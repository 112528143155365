import * as faker from "faker"

export const getMockInventory = () => {
    const ret = []
    for (let i = 0; i < 100; i++) {
        ret.push({
            id: faker.datatype.string(),
            botanicalName: `${faker.name.firstName()} ${faker.name.lastName()}`,
            regionCode: `00000${i}`,
            trackingCode: `BE-${faker.datatype.number(9999999)}`,
            origin: "IT",
            quantity: faker.datatype.number(50),
        })
    }
    return ret
}

export const getMockDocuments = () => {
    const ret = []
    for (let i = 0; i < 100; i++) {
        ret.push({
            id: faker.datatype.string(),
            number: `00${faker.datatype.number(9999)}`,
            type: "Documento di trasporto",
            date: faker.date.future().toISOString(),
            origin: "TV-01-19",
            destination: "TV-03-23",
        })
    }
    return ret
}
