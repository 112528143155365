import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { createUserSettins, updateUserSettings } from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import PageTitle from "../../../../_shared/components/Typography/PageTitle"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useFormHandler from "../../../../_shared/hooks/useFormHandler"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import { useBusinessSelector } from "./settingsHelpers"

Yup.addMethod(
    Yup.array,
    "unique",
    function (message, mapper = (a) => a.number) {
        return this.test("unique", message, function (list) {
            return list.length === new Set(list.map(mapper)).size
        })
    }
)

// ----------------------------------------------------------------------------

function _Settings(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props
    const [{ token }] = useStateValue()

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [settings, , , { fetch }] = useRestResource(
        "/user-settings/me",
        token
    )

    const msg = "Campo obbligatorio"

    const initialValues = {
        ipAddress: "",
        ruopCode: "",
        regionCode: "",
        stateCode: "",
        countryCode: "",
        businessCenters: [],
    }

    const validationSchema = Yup.object().shape({
        ruopCode: Yup.string(),
        regionCode: Yup.string(),
        stateCode: Yup.string(),
        countryCode: Yup.string(),
        businessCenters: Yup.array()
            .of(
                Yup.object().shape({
                    number: Yup.string().required(msg),
                    name: Yup.string().required(msg),
                    id: Yup.string(),
                })
            )
            .unique("I numeri dei centri devono essere univoci"),
    })

    const { bulkChange, getFieldProps, values, handleSubmit } = useFormHandler({
        initialValues,
        validationSchema,
        onSubmit: onSubmit,
        initialTouched: {
            businessCenters: true,
        },
    })

    const { renderSelector } = useBusinessSelector({
        settings: values,
        update,
        getFieldProps,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        settings && bulkChange({ ...values, ...settings })
    }, [settings])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function onSubmit(values) {
        try {
            if (settings && settings.id) {
                await updateUserSettings(settings.id, values, token)
            } else {
                await createUserSettins(values, token)
            }
            fetch()
            message.success("Impostazioni aggiornate")
        } catch (e) {
            console.log(e)
            message.error("Non è stato possibile aggiornare le impostazioni")
        }
    }

    function update(func) {
        const newValues = func(values)
        bulkChange(newValues)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Codice Passaporto Aziendale</PageTitle>

            <div className="wrapper">
                <div className="left">
                    <AppInput
                        {...getFieldProps("ruopCode")}
                        label="Codice RUOP"
                        className="field"
                    ></AppInput>

                    <AppInput
                        {...getFieldProps("stateCode")}
                        label="Codice provincia"
                        className="field"
                    ></AppInput>
                    <AppInput
                        {...getFieldProps("regionCode")}
                        label="Codice regione"
                        className="field"
                    ></AppInput>
                    <AppInput
                        {...getFieldProps("countryCode")}
                        label="Codice stato"
                        className="field"
                    ></AppInput>
                    <AppButton onClick={handleSubmit}>
                        Salva modifiche
                    </AppButton>
                </div>
                <div className="right">{renderSelector()}</div>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Settings.propTypes = {
    className: PropTypes.string.isRequired,
}

_Settings.defaultProps = {}

// ----------------------------------------------------------------------------

const Settings = styled(_Settings)`
    & {
        padding: ${({ theme }) =>
            `${theme.spacing.l}px ${theme.spacing.screenPadding}px`};

        .wrapper {
            display: flex;
            width: 100%;
            .left,
            .right {
                flex: 1;
            }
        }
        .field {
            width: 300px;
            margin-bottom: 20px;
            .app-input {
                background-color: ${({ theme }) => theme.colors.grey300};
                border: 1px solid ${({ theme }) => theme.colors.grey400};
            }
        }
        .centers-wrapper {
            margin-bottom: 30px;
            width: 500px;

            .centers-table {
                .centers-table-delete {
                    svg {
                        stroke: ${({ theme }) => theme.colors.error};
                        width: 20px;
                        height: 20px;
                    }
                }
                .field {
                    width: 200px;
                    margin-bottom: 0;
                }
                th {
                    font-weight: 400;
                }
                .ant-table-placeholder,
                .ant-empty,
                .ant-table-cell {
                    background-color: ${({ theme }) => theme.colors.grey200};

                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.colors.grey200} !important;
                    }
                }
            }

            .new-center {
                display: flex;
                margin-top: 15px;
                align-items: flex-end;

                .field {
                    width: 150px;
                    margin-bottom: 0;
                    margin-right: 15px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Settings
