import { Steps as AntSteps } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const { Step } = AntSteps

// ----------------------------------------------------------------------------

function _Steps(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { current, steps, onChange } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <AntSteps
            current={current}
            className={props.className}
            // onChange={onChange}
        >
            {steps?.map((step) => (
                <Step title={step.title} key={step.title} />
            ))}
        </AntSteps>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Steps.propTypes = {
    className: PropTypes.string.isRequired,
    steps: PropTypes.array,
    current: PropTypes.number,
    onChange: PropTypes.func,
}

_Steps.defaultProps = {}

// ----------------------------------------------------------------------------

const Steps = styled(_Steps)`
    & {
        margin-top: 10px;
        margin-bottom: 30px;

        .ant-steps-item {
            &.ant-steps-item-wait,
            &.ant-steps-item-finish {
                /* .ant-steps-item-container:hover {
                    .ant-steps-item-icon {
                        border-color: ${({ theme }) =>
                    theme.colors.primary} !important;

                        span {
                            color: ${({ theme }) =>
                    theme.colors.primary} !important;
                        }
                    }
                    .ant-steps-item-content {
                        .ant-steps-item-title {
                            color: ${({ theme }) =>
                    theme.colors.primary} !important;
                        }
                    }
                } */
            }
        }

        .ant-steps-item-finish {
            .ant-steps-item-container {
                .ant-steps-item-title::after {
                    background: ${({ theme }) => theme.colors.primary};
                }
                .ant-steps-item-icon {
                    border-color: ${({ theme }) => theme.colors.primary};
                    .ant-steps-icon {
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        }
        .ant-steps-item-active {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    background: ${({ theme }) => theme.colors.primary};
                    border-color: ${({ theme }) => theme.colors.primary};
                    .ant-steps-icon {
                        color: ${({ theme }) => theme.colors.neutralLight};
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Steps
