import { Modal, Tag, message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { BiExport as ExportIcon } from "react-icons/bi"
import styled from "styled-components"

import {
    createDocument,
    createGlobalReportDocument,
    deleteDocument,
    getDownloadLink,
    updateDocument,
} from "../../../../../api"
import { ReactComponent as ImportIcon } from "../../../../_shared/assets/icons/import.svg"
import { ReactComponent as ShareIcon } from "../../../../_shared/assets/icons/share.svg"
import AppButton from "../../../../_shared/components/AppButton"
import Table from "../../../../_shared/components/Table"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import ExportModal from "./ExportModalProxy"
import GlobalReportModal from "./GlobalReportModal"
import ImportModal from "./ImportModal"
import { getDateFilterProps } from "./documentsHelpers"

// ----------------------------------------------------------------------------

function _Documents(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const [{ token }] = useStateValue()

    const [filters, setFilters] = useState({})

    const [
        data,
        ,
        { pagination, loading },
        { fetchResource, onPaginationChange },
    ] = usePaginatedRestResource("/movements", token, {
        defaultFilters: {
            dateFrom: filters?.start?.toISOString(),
            dateTo: filters?.end?.toISOString(),
        },
        refetchOn: [filters],
        debug: false,
    })

    const [settings] = useRestResource("/user-settings/me", token)

    const [modalVisible, setModalVisible] = useState(false)
    const [exportModalVisible, setExportModalVisible] = useState(false)
    const [reportModalVisible, setReportModalVisible] = useState(false)

    const [selectedItem, setSelectedItem] = useState()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function checkSettingsPresent() {
        if (
            settings.ruopCode &&
            settings.stateCode &&
            settings.regionCode &&
            settings?.businessCenters?.length
        ) {
            return true
        }
        return false
    }

    function handlePreviewClick(url) {
        window.open(url, url)
    }

    function renderButton(row) {
        return (
            row?.label && (
                <AppButton
                    uxType="link"
                    onClick={() => handlePreviewClick(row.label)}
                >
                    Stampa passaporti
                </AppButton>
            )
        )
    }

    function renderShare(row) {
        return <ShareIcon />
    }

    function renderImportButton() {
        return (
            <div className="import" onClick={() => setModalVisible(true)}>
                <ImportIcon />
                <span>Inserimento dati fornitori</span>
            </div>
        )
    }

    function renderGlobalReportButton() {
        return (
            <div className="export" onClick={() => setReportModalVisible(true)}>
                <ExportIcon />
                <span>Scarica registro generale carico/scarico</span>
            </div>
        )
    }

    async function handleCreateGlobalReport({ start, end }) {
        const blob = await createGlobalReportDocument(start, end, token)
        const uri = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.setAttribute("href", uri)
        link.setAttribute("target", uri)
        link.setAttribute("download", "report.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    async function handleSubmit(values) {
        try {
            values?.id
                ? await updateDocument(
                      values.id,
                      { ...values, date: values?.date?.toISOString() },
                      token
                  )
                : await createDocument(
                      { ...values, date: values?.date?.toISOString() },
                      token
                  )

            fetchResource()
            setModalVisible(false)
            setSelectedItem(null)
            message.success("Operazione completata")
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Errore")
        }
    }

    async function handleExportDocumentSubmit(values) {
        try {
            await updateDocument(values.id, values, token)

            message.success("Operazione completata")
            setExportModalVisible(false)
            setSelectedItem(null)
            fetchResource()
        } catch (e) {
            console.error(e)
            message.error("Errore")
        }
    }

    function handleDeleteDocuments(docs) {
        async function run() {
            try {
                for (const documentId of docs) {
                    await deleteDocument(documentId, token)
                }
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
                message.error("Operazione fallita")
            }
            fetchResource()
            message.success("Operazione completata")
        }

        Modal.confirm({
            title: "Sei sicuro di voler eliminare i documenti selezionati?",
            onOk: run,
        })
    }

    function handleItemSelected(row) {
        setSelectedItem(row)
        if (row?.type === "import") {
            setModalVisible(true)
        } else {
            if (checkSettingsPresent()) {
                setExportModalVisible(true)
            } else {
                message.error("Le impostazioni non sono configurate")
            }
        }
    }

    function handleModalDismiss() {
        setModalVisible(false)
        setExportModalVisible(false)
        setSelectedItem(null)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Table
                dataSource={data}
                columns={getDocumentColumns({
                    renderButton,
                    renderShare,
                    getDateFilter: () =>
                        getDateFilterProps({ filters, onChange: setFilters }),
                    onItemSelected: handleItemSelected,
                })}
                pagination={pagination}
                loading={loading}
                onChange={(p) => onPaginationChange(p)}
                rowKey={(i) => i.id}
                onDelete={handleDeleteDocuments}
                renderTopRightSection={() => {
                    return (
                        <div className="abs-buttons">
                            {renderGlobalReportButton()}
                            {renderImportButton()}
                        </div>
                    )
                }}
                // onPrint={() => {}}
            />

            <ImportModal
                visible={modalVisible}
                onClose={handleModalDismiss}
                onConfirm={handleSubmit}
                defaultValues={selectedItem}
            />
            <GlobalReportModal
                visible={reportModalVisible}
                onCancel={() => setReportModalVisible(false)}
                onConfirm={handleCreateGlobalReport}
            />
            <ExportModal
                visible={exportModalVisible}
                plants={selectedItem?.plants}
                defaultValues={selectedItem}
                onClose={handleModalDismiss}
                onConfirm={handleExportDocumentSubmit}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Documents.propTypes = {
    className: PropTypes.string.isRequired,
}

_Documents.defaultProps = {}

// ----------------------------------------------------------------------------

const Documents = styled(_Documents)`
    & {
        padding: ${({ theme }) =>
            `${theme.spacing.l}px ${theme.spacing.screenPadding}px`};

        position: relative;

        /* .abs-buttons {
            position: absolute;
            top: ${({ theme }) => theme.spacing.l}px;
            right: ${({ theme }) => theme.spacing.s}px;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
        } */

        .export,
        .import {
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
            padding: 0px 10px;
            border: 1px solid black;
            border-radius: 10px;
            cursor: pointer;
            width: fit-content;

            span {
                display: block;
                margin-left: ${({ theme }) => theme.spacing.xs}px;
                font-weight: 600;
                font-size: 12px;
            }
        }

        .export {
            svg {
                width: 15px;
                height: 15px;
            }
        }

        .import {
        }
    }
`
// ----------------------------------------------------------------------------

export default Documents

export function getDocumentColumns({
    renderButton,
    renderShare,
    getDateFilter,
    onItemSelected,
}) {
    return [
        {
            title: "Tipo",
            key: "type",
            dataIndex: "",
            render: (_, record) => {
                const color = record?.type === "import" ? "orange" : "blue"
                const label =
                    record?.type === "import" ? "Fornitore" : "Cliente"

                return <Tag color={color}>{label}</Tag>
            },
        },
        {
            title: "Ragione sociale",
            key: "supplierOrCustomer",
            render: (row) =>
                row?.type === "import" ? row.supplier : row.customer,
        },
        {
            title: "Tipologia",
            key: "type",
            dataIndex: "type",
            render: (type) => {
                const color = type === "import" ? "orange" : "blue"
                const label = type === "import" ? "Carico" : "Scarico"

                return <Tag color={color}>{label}</Tag>
            },
        },
        {
            title: "Data",
            key: "date",
            dataIndex: "date",
            render: (date) => dayjs(date).format("DD/MM/YYYY"),
            ...getDateFilter?.(),
        },
        // {
        //     title: "Codice origine",
        //     key: "origin",
        //     dataIndex: "origin",
        //     render: () => "???",
        // },
        // {
        //     title: "Codice destinazione",
        //     key: "destination",
        //     dataIndex: "destination",
        //     render: () => "???",
        // },
        {
            title: "Vedi",
            key: "preview",
            render: renderButton,
        },
        // {
        //     title: "",
        //     key: "share",
        //     render: renderShare,
        // },
        {
            title: "",
            key: "download",
            render: (row) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                        }}
                    >
                        <a download href={row.id && getDownloadLink(row)}>
                            <AppButton>Scarica report parziale</AppButton>
                        </a>
                        <AppButton
                            onClick={() => onItemSelected(row)}
                            uxType="plain"
                        >
                            Modifica
                        </AppButton>
                    </div>
                )
            },
        },
    ]
}
