import { DatePicker } from "antd"
import React from "react"

import { CalendarOutlined } from "@ant-design/icons"

const { RangePicker } = DatePicker

function renderFilterDropdown(filters, onChange) {
    return () => {
        const handleChange = (newDate) => {
            if (newDate) {
                const [start, end] = newDate
                onChange({ start, end })
            } else {
                onChange({ start: null, end: null })
            }
        }
        return (
            <div style={{ padding: 15 }}>
                <RangePicker
                    value={[filters.start ?? null, filters.end ?? null]}
                    onChange={handleChange}
                    allowClear
                />
            </div>
        )
    }
}

export function getDateFilterProps({ filters, onChange }) {
    return {
        filterDropdown: renderFilterDropdown(filters, onChange),
        filterIcon: (filtered) => (
            <CalendarOutlined style={{ color: "#4A7265" }} />
        ),
    }
}
