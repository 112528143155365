export default function usePaginatedRestResourceReducer(state, action) {
    switch (action.type) {
        // case "HYDRATE": {
        //     const items = action?.payload?.resource?.data?.items ?? {}
        //     const pagination = {
        //         "page-size": action?.payload?.resource?.data?._meta?.perPage,
        //         page: action?.payload?.resource?.data?._meta?.currentPage,
        //         total: action?.payload?.resource?.data?._meta?.totalCount,
        //     }
        //     return {
        //         ...state,
        //         resource: items,
        //         isLoading: false,
        //         pagination,
        //         initialHydrationStatus: action?.payload?.initialHydrationStatus,
        //     }
        // }
        case "RESET":
            return action.payload
        case "INITIAL_FETCH_DONE":
            return { ...state, isInitialFetch: false }
        case "WAIT_FOR_FULFILLED":
            return {
                ...state,
                waitForFulfilled: true,
                filters: { ...state.filters, ...action.payload },
            }
        case "SHOULD_REFETCH":
            return {
                ...state,
                shouldRefetch: true,
                filters: { ...state.filters, ...action.payload },
                pagination: {
                    ...state.pagination,
                    current: 1,
                },
            }
        case "FETCH_START":
            return { ...state, isLoading: true, shouldRefetch: false }
        case "FETCH_DONE": {
            const items = action?.payload?.rows
            const pagination = {
                ...state?.pagination,
                total: action?.payload?.count ?? 0,
            }

            return { ...state, resource: items, isLoading: false, pagination }
        }
        case "FETCH_FAIL":
            return { ...state, error: action.payload, isLoading: false }
        case "FILTERS_CHANGE":
            return {
                ...state,
                filters: { ...state.filters, ...action.payload },
                pagination: {
                    ...state.pagination,
                    current: 1,
                },
            }
        case "PAGINATION_CHANGE":
            return {
                ...state,
                pagination: { ...state.pagination, ...action.payload },
            }
        case "SORT_CHANGE":
            return { ...state, sort: action.payload }
    }
}

export const usePaginatedRestResourceActions = {
    // hydrate: (resource) => ({
    //     type: "HYDRATE",
    //     payload: {
    //         resource: resource,
    //         initialHydrationStatus: resource ? "success" : "fail",
    //     },
    // }),
    resetHookState: (initialState) => ({
        type: "RESET",
        payload: initialState,
    }),
    initialFetchDone: () => ({ type: "INITIAL_FETCH_DONE" }),
    watiForFulfilled: (updatedFilters) => ({
        type: "WAIT_FOR_FULFILLED",
        payload: updatedFilters,
    }),
    shouldRefetch: (updatedFilters) => ({
        type: "SHOULD_REFETCH",
        payload: updatedFilters,
    }),
    fetchStart: () => ({ type: "FETCH_START" }),
    fetchDone: (res) => ({ type: "FETCH_DONE", payload: res }),
    fetchFail: (error) => ({ type: "FETCH_FAIL", payload: error }),
    changeFilters: (filters) => ({ type: "FILTERS_CHANGE", payload: filters }),
    changePagination: (pag) => ({ type: "PAGINATION_CHANGE", payload: pag }),
    changesort: (sort) => ({ type: "SORT_CHANGE", payload: sort }),
}
