import { Dropdown } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _TableSelect(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, renderSelector, value, onChange, options } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderDropdown() {
        return (
            <div className="options">
                {options?.map((option) => (
                    <div
                        className="option"
                        onClick={() => onChange(option.value)}
                        key={option.value}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        )
    }

    const label = options?.find((option) => option.value === value)?.label
    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Dropdown
                trigger="click"
                getPopupContainer={(trigger) => trigger.parentElement}
                overlay={renderDropdown()}
            >
                {renderSelector({ value, label })}
            </Dropdown>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TableSelect.propTypes = {
    className: PropTypes.string.isRequired,
    renderSelector: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
}

_TableSelect.defaultProps = {}

// ----------------------------------------------------------------------------

const TableSelect = styled(_TableSelect)`
    & {
        .options {
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            ${({ theme }) => theme.shadow};
        }
        .option {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background-color: ${({ theme }) => theme.colors.grey300};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TableSelect
