import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _FieldLabel(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <span className={`${className}`}>{props.children}</span>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FieldLabel.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.any,
}

_FieldLabel.defaultProps = {}

// ----------------------------------------------------------------------------

const FieldLabel = styled(_FieldLabel)`
    & {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
    }
`
// ----------------------------------------------------------------------------

export default FieldLabel
