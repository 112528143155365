import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import useOnClickOutside from "../../hooks/useOnClickOutside"

// ----------------------------------------------------------------------------

function _Modal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, visible, onClose } = props
    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const ref = useRef()
    // useOnClickOutside(ref, () => visible && onClose())

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [visible])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="modal-mask"></div>

            <div className="modal-sheet" ref={ref}>
                {props.children}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Modal.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

_Modal.defaultProps = {}

// ----------------------------------------------------------------------------

const Modal = styled(_Modal)`
    & {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: ${({ visible }) => (visible ? "initial" : "none")};
        opacity: ${({ visible }) => (visible ? 1 : 0)};
        transition: opacity 200ms ease;
        z-index: 100;

        .modal-mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            background-color: rgba(0, 0, 0, 0.5);
        }
        .modal-sheet {
            z-index: 101;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: white;
            border-radius: 20px;

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            min-width: 300px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Modal
