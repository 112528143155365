import { message } from "antd"
import { useFormik } from "formik"
import { useEffect } from "react"

export default function useFormHandler({
    initialValues,
    validationSchema,
    onSubmitWithErrorsMessage,
    onSubmit,
    initialTouched,
}) {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        initialTouched,
        validateOnMount: true,
    })

    useEffect(() => {
        if (formik.submitCount && !formik.isValid) {
            message.error(
                onSubmitWithErrorsMessage ??
                    "Ci sono errori nei campi compilati"
            )
        }
        // eslint-disable-next-line
    }, [formik.submitCount])

    return mapFormikProps(formik)
}

function mapFormikProps(formikProps, serverErrors) {
    return {
        values: formikProps.values,
        onChange: (field) => (value) => formikProps.setFieldValue(field, value),
        bulkChange: formikProps.setValues,
        errors: { ...formikProps.errors, ...serverErrors },
        touched: formikProps.touched,
        onBlur: (field) => () => formikProps.setFieldTouched(field),
        setTouched: formikProps.setTouched,
        isValid: formikProps?.isValid,
        handleSubmit: formikProps.handleSubmit,
        reset: formikProps.resetForm,

        getFieldProps: (field) => {
            const rawProps = formikProps?.getFieldProps(field)
            const rawMeta = formikProps?.getFieldMeta(field)
            const onChange = (value) => formikProps.setFieldValue(field, value)
            const onBlur = () => formikProps.setFieldTouched(field)
            const error = rawMeta?.touched && rawMeta?.error
            const value = rawProps?.value

            return { onChange, onBlur, error, value }
        },
    }
}
