import { message } from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import * as api from "../../../../../../api"
import AppButton from "../../../../../_shared/components/AppButton"
import AppDatePicker from "../../../../../_shared/components/FormItems/AppDatePicker"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import FileUpload from "../../../../../_shared/components/FormItems/FileUpload"
import Modal from "../../../../../_shared/components/Modal"
import PlantSelector from "../../../../../_shared/components/PlantSelector"
import Steps from "../../../../../_shared/components/Steps"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import useFormHandler from "../../../../../_shared/hooks/useFormHandler"
import usePaginatedRestResource from "../../../../../_shared/hooks/usePaginatedRestResource"
import useRestResource from "../../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _ImportModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, visible, onClose, onConfirm, defaultValues } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [step, setStep] = useState(0)

    const initialValues = {
        date: null,
        plants: [],
        type: "import",
        supplier: "",
        ruopCode: "",
    }

    const validationSchema = Yup.object().shape({
        date: Yup.object().nullable().required("Campo obbligatorio"),
        supplier: Yup.string().nullable(),
        ruopCode: Yup.string().nullable(),
        plants: Yup.array()
            .of(
                Yup.object({
                    botanicalName: Yup.string().required("Campo obbligatorio"),
                    trackingCode: Yup.string().required("Campo obbligatorio"),
                    lot: Yup.string().nullable(),
                    businessCenter: Yup.string().nullable(),
                })
            )
            .min(1, "Aggiungere almeno una pianta")
            .required("Aggiungere almeno una pianta"),
    })

    const {
        getFieldProps,
        handleSubmit,
        reset,
        onChange: onFormValueChange,
        bulkChange,
        values,
    } = useFormHandler({
        initialValues,
        validationSchema,
        onSubmit,
    })

    const [settings] = useRestResource("/user-settings/me", token)
    const [lots] = usePaginatedRestResource("/lots", token)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!visible) {
            reset()
            setStep(0)
        }
        visible &&
            defaultValues &&
            bulkChange({
                ...values,
                ...{ ...defaultValues, date: moment(defaultValues.date) },
            })
        // eslint-disable-next-line
    }, [defaultValues, visible])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleFileUpload(file) {
        try {
            const { plants = [] } = await api.importDocumentFile(file, token)

            onFormValueChange?.("plants")(plants)

            message.success("Documento importanto con successo")
        } catch (error) {
            console.error(error)
            message.error("Errore durante il caricamento del file")
        }
    }

    function onSubmit(values) {
        onConfirm && onConfirm(values)
    }

    const next = () => setStep(step + 1)
    const prev = () => setStep(step - 1)

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const baseSteps = [
        {
            title: "Informazioni documento",
            component: (
                <div className="document-info w-full flex flex-col align-start justify-start">
                    <AppDatePicker
                        className="step-input"
                        label="Data"
                        {...getFieldProps("date")}
                    />
                    <AppInput
                        className="step-input"
                        label="Fornitore"
                        {...getFieldProps("supplier")}
                    />
                    <AppInput
                        className="step-input"
                        label="RUOP fornitore"
                        {...getFieldProps("ruopCode")}
                    />
                </div>
            ),
        },
        {
            title: "Modifica file",
            component: (
                <PlantSelector
                    businessCenters={settings?.businessCenters}
                    lots={lots}
                    {...getFieldProps("plants")}
                />
            ),
        },
    ]

    const importMethodSelectionStep = {
        title: "Modalità import",
        component: (
            <div className="file-upload-step">
                <AppButton
                    className="button"
                    uxType="tertiary"
                    onClick={(e) => {
                        e.stopPropagation()
                        setStep(1)
                    }}
                >
                    Inserisci manualmente fornitore
                </AppButton>
                <span>oppure</span>
                <FileUpload
                    className="upload-input"
                    onUpload={handleFileUpload}
                />
            </div>
        ),
    }

    const steps = defaultValues
        ? baseSteps
        : [importMethodSelectionStep, ...baseSteps]

    const onBack = step === 0 ? onClose : prev
    const onNext = step === steps.length - 1 ? handleSubmit : next
    const backText = step === 0 ? "Annulla" : "Indietro"
    const nextText = step === steps.length - 1 ? "Conferma" : "Avanti"

    return (
        <Modal
            className={`${className} flex flex-row`}
            visible={visible}
            onClose={onClose}
        >
            <div className="wrapper w-full flex flex-col">
                <div className="import-modal-content">
                    <Steps current={step} steps={steps}></Steps>
                    <div className="step-content">{steps[step].component}</div>
                </div>
                <div className="buttons flex align-center justify-end w-full">
                    <AppButton uxType="delete" onClick={onBack}>
                        {backText}
                    </AppButton>
                    <AppButton onClick={onNext}>{nextText}</AppButton>
                </div>
            </div>
        </Modal>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ImportModal.propTypes = {
    className: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    defaultValues: PropTypes.object,
}

_ImportModal.defaultProps = {}

// ----------------------------------------------------------------------------

const ImportModal = styled(_ImportModal)`
    & {
        z-index: 1000;
        .modal-sheet {
            border-radius: 0px !important;
        }
        .wrapper {
            padding: 20px;
            max-height: 500px;
            overflow: auto;
            position: relative;

            .buttons {
                button:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        .import-modal-content {
            padding: ${({ theme }) => theme.spacing.s}px;
            width: 60vw;
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;

            .step-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                .file-upload-step {
                    width: 100%;
                    padding: ${({ theme }) => theme.spacing.m}px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .button {
                        width: 130px;
                        height: 100px;
                    }

                    span {
                        display: block;
                        color: ${({ theme }) => theme.colors.grey700};
                    }
                }
                .step-input {
                    margin-bottom: 20px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default ImportModal
