import { Table, message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import TableActions from "../../../../_shared/components/TableActions"
import PageTitle from "../../../../_shared/components/Typography/PageTitle"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import UsersBottomSheet from "./UsersBottomSheet"

// ----------------------------------------------------------------------------

function _Users(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    const [selectedItem, setSelectedItem] = useState()

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [users, , { pagination }, { onPaginationChange, fetchResource }] =
        usePaginatedRestResource("/users", token, {
            defaultFilters: { role: "user" },
        })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderActions(row) {
        return (
            <TableActions
                onEdit={() => setSelectedItem(row)}
                onDelete={() => handleDelete(row)}
            />
        )
    }

    function handleCancel() {
        setSelectedItem(null)
    }

    function handleCreate() {
        setSelectedItem({})
    }

    function handleDelete(user) {
        api.deleteUser(user.id, token)
            .then(() => {
                fetchResource()
                message.success("Utente eliminato")
            })
            .catch((e) => {
                console.log(e)
                message.error("Eliminazione fallita")
            })
    }

    async function handleSubmit(values) {
        try {
            console.log("values", values)
            if (values.id) {
                await api.updateUser(values.id, values, token)
            } else {
                await api.signUp(values)
            }
            fetchResource()
            message.success("Operazione completata")
            handleCancel()
        } catch (e) {
            message.error("Non è stato possibile compleatare l'operazione")
            console.log(e)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Utenti</PageTitle>
            <AppButton onClick={handleCreate} className="button">
                Crea nuovo utente
            </AppButton>
            <Table
                dataSource={users}
                pagination={pagination}
                columns={getColumns(renderActions)}
                onChange={onPaginationChange}
                rowKey={(i) => i.id}
            />
            <UsersBottomSheet
                open={!!selectedItem}
                defaultValues={selectedItem}
                onCancel={handleCancel}
                onConfirm={handleSubmit}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Users.propTypes = {
    className: PropTypes.string.isRequired,
}

_Users.defaultProps = {}

// ----------------------------------------------------------------------------

const Users = styled(_Users)`
    & {
        .button {
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Users

function getColumns(renderActions) {
    return [
        {
            title: "Ragione sociale",
            key: "businessName",
            dataIndex: "businessName",
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        { key: "actions", render: renderActions },
    ]
}
