import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import AppDateRangePicker from "../../../../../_shared/components/FormItems/AppDateRangePicker"

// ----------------------------------------------------------------------------

function _GlobalReportModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, visible, onConfirm, onCancel } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const ref = useRef(null)
    const [dateRange, setDateRange] = useState({ start: null, end: null })
    const [loading, setLoading] = useState(false)
    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        !visible && setDateRange([null, null])
    }, [visible])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm() {
        if (dateRange?.start && dateRange?.end) {
            const { start, end } = dateRange
            if (onConfirm) {
                setLoading(true)
                return onConfirm({
                    start: start.toISOString(),
                    end: end.toISOString(),
                }).finally(() => setLoading(false))
            }
        } else {
            message.error("Selezionare intervallo")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div ref={ref} className={`${className}`}>
            <Modal
                getContainer={() => ref.current}
                title="Crea report globale"
                onOk={handleConfirm}
                onCancel={onCancel}
                visible={visible}
                confirmLoading={loading}
            >
                <AppDateRangePicker
                    label="Seleziona l'intervallo di tempo per i documenti"
                    value={[dateRange?.start ?? null, dateRange?.end ?? null]}
                    onChange={setDateRange}
                    className="field"
                />
            </Modal>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_GlobalReportModal.propTypes = {
    className: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

_GlobalReportModal.defaultProps = {}

// ----------------------------------------------------------------------------

const GlobalReportModal = styled(_GlobalReportModal)`
    & {
        .field {
        }
    }
`
// ----------------------------------------------------------------------------

export default GlobalReportModal
