import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as PrintIcon } from "../../../assets/icons/print.svg"
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg"

// ----------------------------------------------------------------------------

function _TableTop(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, selectedKeys, onPrint, onDelete } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderNumSelected() {
        const num = selectedKeys?.length || 1
        return (
            <div className="num-selected">
                {num} {num > 1 ? "Selezionati" : "Selezionato"}
            </div>
        )
    }

    function renderDelete() {
        return (
            onDelete && (
                <div
                    className="button button-delete"
                    onClick={() => onDelete(selectedKeys)}
                >
                    <TrashIcon />
                    <span className="button-text">Cancella</span>
                </div>
            )
        )
    }

    function renderPrint() {
        return (
            onPrint && (
                <div
                    className="button button-print"
                    onClick={() => onPrint(selectedKeys)}
                >
                    <PrintIcon />
                    <span className="button-text">Stampa passaporti</span>
                </div>
            )
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderNumSelected()}

            <div className="buttons">
                {renderPrint()}
                {onPrint && onDelete && <div className="button-spacer" />}
                {renderDelete()}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TableTop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
    selectedKeys: PropTypes.array,
    onDelete: PropTypes.func,
    onPrint: PropTypes.func,
}

_TableTop.defaultProps = {}

// ----------------------------------------------------------------------------

const TableTop = styled(_TableTop)`
    & {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        height: ${({ open }) => (open ? "75px" : "0px")};
        transition: height 200ms ease;
        background-color: ${({ theme }) => theme.colors.grey400};

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px ${({ theme }) => theme.spacing.s}px;

        overflow: hidden;

        .num-selected {
            font-weight: 600;
        }

        .buttons {
            .button-spacer {
                height: ${({ theme }) => theme.spacing.xs}px;
            }
            .button {
                width: 200px;
                height: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                cursor: pointer;

                .button-text {
                    font-weight: 500;
                    display: block;
                    font-size: 12px;
                    margin-left: ${({ theme }) => theme.spacing.xs}px;
                }
            }
            .button-delete {
                background-color: ${({ theme }) => theme.colors.error200};
                .button-text {
                    color: ${({ theme }) => theme.colors.error};
                }
            }
            .button-print {
                background-color: white;
                .button-text {
                    color: ${({ theme }) => theme.colors.grey700};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TableTop
