import { Table, message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import { createPlant, deletePlant, updatePlant } from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import TableActions from "../../../../_shared/components/TableActions"
import PageTitle from "../../../../_shared/components/Typography/PageTitle"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import PlantsBottomSheet from "./PlantsBottomSheet"

// ----------------------------------------------------------------------------

function _Plants(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [plants, , { pagination }, { onPaginationChange, fetchResource }] =
        usePaginatedRestResource("/plants", token)

    const [selectedItem, setSelectedItem] = useState()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderActions(row) {
        return (
            <TableActions
                onEdit={() => setSelectedItem(row)}
                onDelete={() => handleDelete(row)}
            />
        )
    }

    async function handleDelete(plant) {
        try {
            await deletePlant(plant.id, token)
            fetchResource()
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Qualcosa è andato storto")
        }
    }

    function handleCancel() {
        setSelectedItem(null)
    }

    function handleCreate() {
        setSelectedItem({})
    }

    async function handleSubmit(values) {
        try {
            values?.id
                ? await updatePlant(values.id, values, token)
                : await createPlant(values, token)

            fetchResource()
            message.success("Operazione completata")
            setSelectedItem(null)
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Qualcosa è andato storto")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Piante</PageTitle>
            <AppButton onClick={handleCreate} className="button">
                Crea nuovo utente
            </AppButton>
            <Table
                columns={getColumns(renderActions)}
                dataSource={plants}
                pagination={pagination}
                onChange={(p) => onPaginationChange(p)}
                rowKey={(i) => i.id}
            ></Table>
            <PlantsBottomSheet
                open={!!selectedItem}
                defaultValues={selectedItem}
                onCancel={handleCancel}
                onConfirm={handleSubmit}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Plants.propTypes = {
    className: PropTypes.string.isRequired,
}

_Plants.defaultProps = {}

// ----------------------------------------------------------------------------

const Plants = styled(_Plants)`
    & {
        .button {
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Plants

function getColumns(renderActions) {
    return [
        {
            title: "Nome botanico",
            key: "botanicalName",
            dataIndex: "botanicalName",
        },
        {
            title: "Genus",
            key: "genus",
            dataIndex: "genus",
        },
        {
            title: "Specie",
            key: "species",
            dataIndex: "species",
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
