import PropTypes from "prop-types"
import React, { useImperativeHandle, useRef } from "react"
import styled from "styled-components"

import FieldLabel from "../../FieldLabel"
import ValidationMessage from "../ValidationMessage"

// ----------------------------------------------------------------------------

const _AppInput = React.forwardRef((props, ref) => {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { label, className, onChange, id, error, ...inputProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        focus: focusThis,
    }))

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function focusThis() {
        if (!id) {
            throw new Error("Must have id to focus input")
        }
        const elem = document.getElementById(id)
        elem.focus()
    }

    function handleChange(e) {
        onChange && onChange(e.target.value)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <input
                className="app-input"
                id={id}
                {...inputProps}
                ref={inputRef}
                onChange={handleChange}
            ></input>
            {error && <ValidationMessage>{error}</ValidationMessage>}
        </div>
    )
})

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppInput.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    id: PropTypes.string,
    error: PropTypes.any,
    label: PropTypes.string,
}

_AppInput.defaultProps = {}

// ----------------------------------------------------------------------------

const AppInput = styled(_AppInput)`
    & {
        max-width: 300px;

        overflow: hidden;

        .app-input {
            border-radius: 10px;
            background-color: ${({ theme }) => theme.colors.grey200};
            border: 1px solid ${({ theme }) => theme.colors.grey300};
            display: block;
            width: 100%;
            /* border: none; */
            outline: none;
            /* background-color: transparent; */
            padding: 0px ${({ theme }) => theme.spacing.s}px;
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default AppInput
