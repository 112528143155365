import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { useTheme } from "styled-components"

import * as api from "../../../../api"
import Logo from "../../../_shared/assets/flor_logo.png"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const theme = useTheme()

    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            const { user, token } = await api.signIn(email, password)
            dispatch({ type: "SIGN_IN_FULFILLED", payload: { user, token } })
        } catch (e) {
            message.error("Credenziali rifiutate")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="logo">
                <img src={Logo}></img>
            </div>
            <form className="login-card" onSubmit={handleSubmit}>
                <h1 className="heading">Accedi al tuo account</h1>
                <AppInput
                    className="input input-first"
                    value={email}
                    onChange={setEmail}
                    placeholder="E-mail"
                ></AppInput>
                <AppInput
                    className="input input-last"
                    value={password}
                    onChange={setPassword}
                    placeholder="Password"
                    type="password"
                ></AppInput>
                <AppButton className="btn" uxType="secondary" htmlType="submit">
                    Continua
                </AppButton>
                <div className="divider"></div>
                <div className="row">
                    <span className="inner">Non riesci ad accedere?</span>
                    <div className="dot" />
                    <span className="inner">Sign up for new user?</span>
                </div>
            </form>
            <div className="row" style={{ marginTop: theme.spacing.s }}>
                <span className="outer">Privacy policy</span>
                <div className="dot" />
                <span className="outer">Terms of use</span>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

_AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const AuthPage = styled(_AuthPage)`
    & {
        width: 100vw;
        height: 100vh;
        background-color: ${({ theme }) => theme.colors.primary200};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: ${({ theme }) => theme.spacing.l}px;
            img {
                object-fit: contain;
                width: 207px;
                height: 76px;
            }
        }

        .login-card {
            min-height: 200px;
            width: 330px;
            background-color: white;
            border-radius: 20px;
            padding: 30px 20px;

            h1.heading {
                margin: 0;
                padding: 0;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                margin-bottom: ${({ theme }) => theme.spacing.s}px;
            }

            .input {
                max-width: unset;
                width: 100%;
                border-radius: 15px;
            }

            .input-first {
                margin-bottom: ${({ theme }) => theme.spacing.xs}px;
            }
            .input-last {
                margin-bottom: ${({ theme }) => theme.spacing.s}px;
            }

            .btn {
                max-width: unset;
                width: 100%;
            }

            .divider {
                margin-top: ${({ theme }) => theme.spacing.m}px;
                margin-bottom: ${({ theme }) => theme.spacing.s}px;
                width: 100%;
                height: 1px;
                background-color: ${({ theme }) => theme.colors.grey300};
            }
        }
        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            span.inner {
                font-size: 11px;
                font-weight: 500;
                text-align: center;
                display: block;
                color: ${({ theme }) => theme.colors.grey600};
            }
            span.outer {
                font-size: 12px;
                text-align: center;
                display: block;
            }
        }
        .dot {
            width: 3px;
            height: 3px;
            border-radius: 999px;
            background-color: ${({ theme }) => theme.colors.dark};
            margin: 0px ${({ theme }) => theme.spacing.xs}px;
            overflow: hidden;
        }
    }
`
// ----------------------------------------------------------------------------

export default AuthPage
