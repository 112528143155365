import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import Table from "../../../../_shared/components/Table"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import ExportModal from "./ExportModal"

// ----------------------------------------------------------------------------

function _Inventory(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [data, , , { fetch: fetchResource }] = useRestResource(
        "/warehouse/me",
        token
    )

    const [settings] = useRestResource("/user-settings/me", token)

    const [plants, setPlants] = useState([])
    const [modalOpen, setModalOpen] = useState(false)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data?.entries?.length,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        setPagination({ ...pagination, total: data?.entries?.length })
        // eslint-disable-next-line
    }, [data])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderActions() {
        return <AppButton uxType="link">Preview</AppButton>
    }

    function renderQuantity(quantity) {
        return <div className="quantity">{quantity}</div>
    }

    function checkSettinsPresent() {
        if (
            settings.ruopCode &&
            settings.stateCode &&
            settings.regionCode &&
            settings?.businessCenters?.length
        ) {
            return true
        }
        return false
    }

    function handlePrint(selection) {
        if (checkSettinsPresent()) {
            setPlants(populatePlants(selection))
            setModalOpen(true)
        } else {
            message.error("Le impostazioni non sono configurate")
        }
    }

    function populatePlants(names) {
        return names?.map((name) =>
            data?.entries?.find((entry) => buildKey(entry) === name)
        )
    }

    function downloadURI(uri, name) {
        const link = document.createElement("a")
        link.setAttribute("href", uri)
        link.setAttribute("target", uri)
        link.setAttribute("download", name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    async function handleDocumentCreate(values) {
        try {
            const { id, number } = await api.createDocument(values, token)

            const { url } = await api.getDocumentLabel(id, token)

            downloadURI(url, `${number}.pdf`)

            message.success("Documento creato")
            setModalOpen(false)
            setPlants([])
        } catch (e) {
            console.error(e)
            message.error("Creazione fallita")
        }
    }

    async function handlePlantsDelete(ids) {
        async function run() {
            try {
                for (const id of ids) {
                    const plant = data?.entries?.find(
                        (entry) => buildKey(entry) === id
                    )

                    await api.deleteWarehousePlants(
                        `/warehouse/entries?botanicalName=${plant.botanicalName}&trackingCode=${plant.trackingCode}`,
                        token
                    )
                }

                fetchResource()

                message.success("Piante eliminate con successo.")
            } catch (error) {
                console.error(error)
                message.error("Si è verificato un errore. Riprovare piu tardi.")
            }
        }
        Modal.confirm({
            title: "Sei sicuro di voler eliminare le piante selezionate?",
            onOk: run,
        })
    }

    function buildKey(row) {
        return `${row.botanicalName}/${row.trackingCode}`
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Table
                dataSource={data?.entries}
                columns={getColumns(renderActions, renderQuantity)}
                pagination={pagination}
                onDelete={handlePlantsDelete}
                onPrint={handlePrint}
                rowKey={(i) => buildKey(i)}
                onChange={(p) => setPagination({ ...pagination, ...p })}
            />
            <ExportModal
                settings={settings}
                plants={plants}
                visible={modalOpen}
                onClose={() => {
                    setPlants([])
                    setModalOpen(false)
                }}
                onConfirm={handleDocumentCreate}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Inventory.propTypes = {
    className: PropTypes.string.isRequired,
}

_Inventory.defaultProps = {}

// ----------------------------------------------------------------------------

const Inventory = styled(_Inventory)`
    & {
        padding: ${({ theme }) =>
            `${theme.spacing.l}px ${theme.spacing.screenPadding}px`};

        .quantity {
            height: 38px;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: 1px solid ${({ theme }) => theme.colors.grey600};
        }
        .ant-table-row-selected .quantity {
            border: 1px solid white;
        }
    }
`
// ----------------------------------------------------------------------------

export default Inventory

function getColumns(renderActions, renderQuantity) {
    return [
        {
            title: "Nome botanico",
            key: "name",
            dataIndex: "botanicalName",
        },
        // {
        //     title: "Codice regione",
        //     key: "origin",
        //     dataIndex: "regionCode",
        //     render: () => "???",
        // },
        {
            title: "Codice tracciabilità",
            key: "tracking",
            dataIndex: "trackingCode",
        },
        // {
        //     title: "Paese origine",
        //     key: "origin",
        //     dataIndex: "origin",
        //     render: () => "???",
        // },

        // {
        //     title: "Vedi",
        //     key: "preview",
        //     render: renderActions,
        // },
    ]
}
