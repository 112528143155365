import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import Modal from "../../../../../_shared/components/Modal/index"

// ----------------------------------------------------------------------------

function _ExportModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        visible,
        onClose,
        onConfirm,
        plants,
        settings,
        defaultValues,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [quantities, setQuantities] = useState({})
    const [codes, setCodes] = useState({})
    const [customer, setCustomer] = useState("")
    const [ruopCode, setRuopCode] = useState("")

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        const newCodes = {}
        const newQuantites = {}
        plants &&
            plants.forEach((plant) => {
                newCodes[plant._id] = plant.trackingCode
                newQuantites[plant._id] = plant.quantity ?? 0
            })
        setCodes(newCodes)
        setQuantities(newQuantites)
    }, [plants])

    useEffect(() => {
        if (defaultValues) {
            setCustomer(defaultValues?.customer ?? "")
            setRuopCode(defaultValues?.ruopCode ?? "")
        }
    }, [defaultValues])

    useEffect(() => {
        if (!visible) {
            setCodes({})
            setQuantities({})
            setCustomer("")
            setRuopCode("")
        }
    }, [visible])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderPlant(plant) {
        return (
            <div
                className="plant flex flex-row align-center w-full"
                key={plant?.botanicalName}
            >
                <div
                    className="plant-name flex-center"
                    style={{ marginRight: 10 }}
                >
                    {plant?.botanicalName}
                </div>
                <div className="inputs flex-center">
                    <AppInput
                        className="input"
                        value={quantities?.[plant?._id] ?? "0"}
                        onChange={(e) =>
                            setQuantities({
                                ...quantities,
                                [plant?._id]: e,
                            })
                        }
                    />
                    <AppInput
                        className="input"
                        value={codes?.[plant?._id] ?? "0"}
                        onChange={(e) =>
                            setCodes({
                                ...codes,
                                [plant?._id]: e,
                            })
                        }
                    />
                </div>
            </div>
        )
    }

    function renderPlants() {
        return (
            <div className="plants">
                {plants?.map((plant) => renderPlant(plant))}
            </div>
        )
    }

    function buildPlants() {
        return plants?.map((plant) => ({
            ...plant,
            trackingCode: codes?.[plant?._id],
            quantity: quantities?.[plant?._id],
            passport: {
                a: `${plant?.botanicalName}`,
                b: `${settings?.countryCode}-${settings?.regionCode}-${settings?.ruopCode}`,
                c: `${plant?.trackingCode}`,
                d: `${settings?.countryCode}`,
            },
        }))
    }

    function handleConfirm() {
        onConfirm &&
            onConfirm({
                type: "export",
                date: dayjs().toISOString(),
                plants: buildPlants(),
                customer,
                ruopCode,
                id: defaultValues?.id,
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Modal
            className={`${className} flex flex-row`}
            visible={visible}
            onClose={onClose}
        >
            <div className="wrapper">
                <h3>Inserire cliente/Inserire numero passaporti da stampare</h3>
                <AppInput
                    label="Cliente"
                    value={customer}
                    onChange={setCustomer}
                    className="customer-field"
                />
                <AppInput
                    label="RUOP cliente"
                    value={ruopCode}
                    onChange={setRuopCode}
                    className="customer-field"
                />
                {renderPlants()}

                <div className="buttons flex align-center justify-end w-full">
                    <AppButton uxType="delete" onClick={onClose}>
                        Annulla
                    </AppButton>
                    <AppButton onClick={handleConfirm}>Conferma</AppButton>
                </div>
            </div>
        </Modal>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ExportModal.propTypes = {
    className: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    plants: PropTypes.array,
    settings: PropTypes.object,
    defaultValues: PropTypes.object,
}

_ExportModal.defaultProps = {}

// ----------------------------------------------------------------------------

const ExportModal = styled(_ExportModal)`
    & {
        z-index: 1000;
        .modal-sheet {
            border-radius: 0px !important;
        }
        .wrapper {
            padding: 20px;
            max-height: 500px;
            overflow: auto;
            position: relative;
            width: 500px;

            .customer-field {
                margin-bottom: 10px;
            }

            .plants {
                margin-bottom: 20px;
                margin-top: 30px;
                .plant {
                    border-bottom: 1px solid
                        ${({ theme }) => theme.colors.grey400};
                    padding-bottom: 15px;
                    margin-bottom: 10px;
                    .plant-name {
                        display: flex;
                        justify-content: flex-start;
                        width: 200px;
                        font-weight: 500;
                    }
                    .inputs {
                        .input {
                            &:not(:last-child) {
                                width: 200px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .buttons {
                button:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default ExportModal
