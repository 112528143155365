import PropTypes from "prop-types"
import React from "react"
import { AiOutlinePoweroff } from "react-icons/ai"
import { BiCookie, IoCodeSlash as CodeIcon } from "react-icons/all"
import { BsFillPersonFill as PersonIcon } from "react-icons/bs"
import { useLocation, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Image from "../../../assets/flor_logo.png"
import { useStateValue } from "../../../context/AppStateStore"

// ----------------------------------------------------------------------------

function _AdminMenu(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [, dispatch] = useStateValue()

    const location = useLocation()
    const match = useRouteMatch()
    const { pathname } = location

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleLogout() {
        return dispatch({
            type: "SIGN_OUT",
        })
    }

    function getIsActive(className, strict) {
        if (strict) return className === pathname && "menu-link active"
        else {
            const pat = new RegExp(className)
            return pat.test(pathname) && "menu-link active"
        }
    }

    function getPath(path) {
        return `/admin${path}`
    }

    function renderLinks() {
        return (
            <div className="links">
                <Link
                    to={getPath("/")}
                    className={`menu-item ${getIsActive(getPath("/"), true)}`}
                >
                    <div className="icon">
                        <PersonIcon className="icn" />
                    </div>
                    <span className="menu-text">Utenti</span>
                </Link>
                <Link
                    to={getPath("/plants")}
                    className={`menu-item ${getIsActive(
                        getPath("/plants"),
                        true
                    )}`}
                >
                    <div className="icon">
                        <PersonIcon className="icn" />
                    </div>
                    <span className="menu-text">Piante</span>
                </Link>
                {/* <div className="divider"></div> */}
                {/* <Link
                    to={getPath("/templates")}
                    className={`menu-item ${getIsActive(
                        getPath("/templates"),
                        false
                    )}`}
                >
                    <div className="icon">
                        <CodeIcon />
                    </div>
                    <span className="menu-text">Template</span>
                </Link> */}
                {/* <div className="divider"></div> */}
                {/* <Link
                    to={getPath("/cookies")}
                    className={`menu-item ${getIsActive(
                        getPath("/cookies"),
                        false
                    )}`}
                >
                    <div className="icon">
                        <BiCookie />
                    </div>
                    <span className="menu-text">Cookies</span>
                </Link> */}
                {/*
                <Link
                    to="/documents"
                    className={`menu-item ${getIsActive("/documents", false)}`}
                >
                    <div className="icon">
                        <DocumentIcon />
                    </div>
                    <span className="menu-text">Documenti</span>
                </Link> */}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="logo">
                <img className="logo-img" src={Image} alt="logo"></img>
            </div>

            {renderLinks()}

            <div className="logout" onClick={handleLogout}>
                <div className="icon">
                    <AiOutlinePoweroff />
                </div>
                <span className="logout-text">Logout</span>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AdminMenu.propTypes = {
    className: PropTypes.string.isRequired,
}

_AdminMenu.defaultProps = {}

// ----------------------------------------------------------------------------

const AdminMenu = styled(_AdminMenu)`
    & {
        width: 250px;
        height: 100vh;
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(#4a4a4a, #44444f);
        border-radius: 0 20px 20px 0;

        .logo {
            /* min-height: 150px; */
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .logo-img {
                width: 180px;
                height: 100%;
                object-fit: contain;
            }
        }

        .links {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            .divider {
                margin: 10px 0px;
                width: 90%;
                height: 1px;
                background: rgb(31, 40, 58);
                box-shadow: rgba(255, 255, 255, 0.05) 0px 1px 0px;
                border-radius: 30px;
            }
            .menu-item {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 22px;
                padding-right: 0;
                color: ${(props) => props.theme.colors.light};
                transition: all 200ms ease;
                margin-bottom: 10px;

                .icon {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    * {
                        color: ${(props) => props.theme.colors.white};
                        width: 18px;
                        height: 18px;
                    }
                }
                .menu-text {
                    color: ${(props) => props.theme.colors.white};
                    font-weight: normal;
                    display: block;
                    font-size: 14px;
                }

                &.active {
                    position: relative;
                    padding-left: 0;
                    margin-left: 22px;

                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    height: 40px;
                    width: calc(100% - 22px);

                    overflow: visible;
                    background-color: ${(props) => props.theme.colors.light};

                    ::before,
                    ::after {
                        box-sizing: border-box;
                    }

                    ::before {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 10px;
                        background: transparent;
                        bottom: -20px;
                        right: 0px;
                        border-top-right-radius: 10px;
                        box-shadow: rgb(255, 255, 255) 1px -10px 0px 0px;
                    }

                    ::after {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 10px;
                        background: transparent;
                        top: -20px;
                        right: 0px;
                        border-bottom-right-radius: 10px;
                        box-shadow: rgb(255, 255, 255) 1px 10px 0px 0px;
                    }

                    .menu-text {
                        font-weight: 500;
                        color: ${(props) => props.theme.colors.dark} !important;
                    }
                    .icon {
                        margin-left: 20px;

                        * {
                            color: ${(props) => props.theme.colors.dark};
                        }
                    }
                }
            }
        }

        .logout {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon {
                display: block;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    fill: ${({ theme }) => theme.colors.light};
                    width: 16px;
                    height: 16px;
                }
            }
            .logout-text {
                display: block;
                color: ${({ theme }) => theme.colors.light};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AdminMenu
