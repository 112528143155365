import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _FileUpload(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onUpload } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleUpload(e) {
        onUpload(e.target.files[0])
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="file-upload-ui">
                <div className="upload-plus">+</div>
                <div className="upload-text">Carica file</div>
            </div>
            <input
                className="file-input-inner"
                type="file"
                onChange={handleUpload}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FileUpload.propTypes = {
    className: PropTypes.string.isRequired,
}

_FileUpload.defaultProps = {}

// ----------------------------------------------------------------------------

const FileUpload = styled(_FileUpload)`
    & {
        width: 130px;
        height: 130px;
        position: relative;
        .file-upload-ui {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background-color: ${({ theme }) => theme.colors.grey300};
            border: 1px dashed ${({ theme }) => theme.colors.grey400};

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            transition: all 200ms ease;

            .upload-plus {
                font-size: 18px;
            }
            .upload-text {
            }
        }
        .file-input-inner {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            opacity: 0;
            top: 0;
            cursor: pointer;
        }

        &:hover {
            .file-upload-ui {
                background-color: ${({ theme }) => theme.colors.grey400};
                border: 1px dashed ${({ theme }) => theme.colors.grey600};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default FileUpload
