import { Table } from "antd"
import React from "react"
import { FiTrash2 as DeleteIcon } from "react-icons/fi"
import * as Yup from "yup"

import AppButton from "../../../../_shared/components/AppButton"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import ValidationMessage from "../../../../_shared/components/FormItems/ValidationMessage"
import useFormHandler from "../../../../_shared/hooks/useFormHandler"

export function useBusinessSelector({ settings, getFieldProps, update }) {
    const initialValues = {
        number: "",
        name: "",
    }
    const msg = "Campo obbligatorio"
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(msg),
        number: Yup.string().required(msg),
    })

    const {
        getFieldProps: getNewCenterFieldProps,
        handleSubmit,
        reset,
    } = useFormHandler({
        initialValues,
        onSubmit: handleCenterCreate,
        validationSchema,
    })

    const { error } = getFieldProps(`businessCenters`)

    function handleCenterCreate(values) {
        update((prevValues) => ({
            ...prevValues,
            businessCenters: [
                ...prevValues.businessCenters,
                { ...values, tempId: Math.random().toString() },
            ],
        }))
        reset()
    }

    function handleCenterDelete(record) {
        const getIdent = (center) => center.id ?? center.tempId

        update((prevValues) => ({
            ...prevValues,
            businessCenters: prevValues.businessCenters.filter(
                (center) => getIdent(record) !== getIdent(center)
            ),
        }))
    }

    const columns = [
        {
            title: "Nome centro",
            dataIndex: "title",
            key: "title",
            render: (value, record, index) => (
                <AppInput
                    className="field"
                    {...getFieldProps(`businessCenters[${index}].name`)}
                />
            ),
        },
        {
            title: "Numero centro",
            dataIndex: "number",
            key: "number",
            render: (value, record, index) => (
                <AppInput
                    className="field"
                    {...getFieldProps(`businessCenters[${index}].number`)}
                />
            ),
        },
        {
            key: "actions",
            render: (record) => (
                <div
                    className="centers-table-delete"
                    onClick={() => handleCenterDelete(record)}
                >
                    <DeleteIcon />
                </div>
            ),
        },
    ]

    const renderSelector = () => (
        <div className="centers-wrapper">
            <span
                className=""
                style={{ display: "block", marginBottom: 15, fontWeight: 500 }}
            >
                Centri aziendali
            </span>
            <Table
                dataSource={settings?.businessCenters}
                columns={columns}
                className="centers-table"
                pagination={false}
                bordered
            />
            {error && typeof error === "string" && (
                <ValidationMessage>{error}</ValidationMessage>
            )}
            <form onSubmit={handleSubmit} className="new-center">
                <AppInput
                    label="Nome nuovo centro"
                    className="field"
                    {...getNewCenterFieldProps(`name`)}
                    error={""}
                />
                <AppInput
                    label="Numero nuovo centro"
                    className="field"
                    {...getNewCenterFieldProps(`number`)}
                    error={""}
                />

                <AppButton uxType="plain">Aggiungi centro</AppButton>
            </form>
        </div>
    )

    return {
        renderSelector,
    }
}
