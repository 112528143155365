import PropTypes from "prop-types"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"

import Logo from "../../../assets/flor_logo.png"
import HeaderRight from "./HeaderRight"

// ----------------------------------------------------------------------------

function _Header(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { pathname: path } = useLocation()
    const lineLeft = getLineLeft()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getLineLeft() {
        if (path === "/") {
            return 0
        } else if (path === "/magazzino") {
            return linkWidth + linkMargin
        } else if (path === "/lotti") {
            return (linkWidth + linkMargin) * 2
        }
    }

    function getLineShowing() {
        return path === "/magazzino" || path === "/" || path === "/lotti"
    }

    function renderLogo() {
        return (
            <div className="logo">
                <img
                    src={Logo}
                    alt="Logo florveneto"
                    width={132}
                    height={42}
                ></img>
            </div>
        )
    }

    function renderLinks() {
        return (
            <div className="links">
                <div className="link-row">
                    <Link className={`link ${path === "/" && "active"}`} to="/">
                        Registro Passaporti
                    </Link>
                    <div className="spacer" />
                    <Link
                        className={`link ${path === "/magazzino" && "active"}`}
                        to="/magazzino"
                    >
                        Magazzino Piante
                    </Link>
                    <div className="spacer" />
                    <Link
                        className={`link ${path === "/lotti" && "active"}`}
                        to="/lotti"
                    >
                        Inserimento Lotti
                    </Link>
                </div>
                {getLineShowing() && (
                    <div className="line" style={{ left: lineLeft }} />
                )}
            </div>
        )
    }

    function renderRight() {
        return <HeaderRight className="right"></HeaderRight>
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderLogo()}
            {renderLinks()}
            {renderRight()}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Header.propTypes = {
    className: PropTypes.string.isRequired,
}

_Header.defaultProps = {}

// ----------------------------------------------------------------------------

const linkWidth = 150
const linkMargin = 25

const Header = styled(_Header)`
    & {
        width: 100vw;
        height: 70px;
        background-color: white;
        box-shadow: inset 0px -1px 0px #e2e2ea;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px ${({ theme }) => theme.spacing.s}px;

        position: relative;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            left: ${({ theme }) => theme.spacing.s}px;

            img {
                object-fit: contain;
            }
        }

        .links {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .link-row {
                display: flex;
                align-items: center;
                justify-content: center;

                .spacer {
                    width: ${linkMargin}px;
                }
                .link {
                    color: inherit;
                    width: ${linkWidth}px;
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    transition: all 200ms ease;

                    &:hover {
                        color: ${({ theme }) => theme.colors.primary};
                    }

                    &.active {
                        color: ${({ theme }) => theme.colors.primary};
                        font-weight: 600;
                    }
                }
            }

            .line {
                height: 3px;
                width: ${linkWidth}px;
                background-color: ${({ theme }) => theme.colors.primary};
                margin-top: auto;
                position: absolute;
                bottom: 0;
                left: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transition: left 200ms ease;
            }
        }

        .right {
            position: absolute;
            right: ${({ theme }) => theme.spacing.s}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Header
