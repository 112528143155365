import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { LoadingOutlined } from "@ant-design/icons"

// ----------------------------------------------------------------------------

function _FloatingButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onClick, children, loading } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div onClick={onClick} className={`${className}`}>
            {loading ? <LoadingOutlined /> : children}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FloatingButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.object,
}

_FloatingButton.defaultProps = {}

// ----------------------------------------------------------------------------

const FloatingButton = styled(_FloatingButton)`
    & {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    }
`
// ----------------------------------------------------------------------------

export default FloatingButton
