import { Dropdown } from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FiFilter } from "react-icons/fi"
import styled from "styled-components"

import AppButton from "../../AppButton"
import AppDateRangePicker from "../AppDateRangePicker"
import AppSelect from "../AppSelect"

// ----------------------------------------------------------------------------

function _DocsFilter(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onFilter } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [visible, setVisible] = useState(false)
    const [filters, setFilters] = useState({
        type: null,
        dateFrom: null,
        dateTo: null,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleDateSelected({ start, end }) {
        setFilters({
            ...filters,
            dateFrom: start?.format("YYYY-MM-DD") ?? null,
            dateTo: end?.format("YYYY-MM-DD") ?? null,
        })
    }

    function handleConfirm() {
        onFilter && onFilter(filters)
    }

    function renderDropdown() {
        return (
            <div className="filter-dropdown">
                <AppSelect
                    className="w-full field"
                    label="Tipologia"
                    options={[
                        { label: "Import", value: "import" },
                        { label: "Export", value: "export" },
                    ]}
                    value={filters.type}
                    onChange={(type) => setFilters((f) => ({ ...f, type }))}
                    allowClear
                ></AppSelect>
                <AppDateRangePicker
                    label="Data"
                    className="field"
                    onChange={handleDateSelected}
                    value={[
                        filters.dateFrom ? moment(filters.dateFrom) : null,
                        filters.dateTo ? moment(filters.dateTo) : null,
                    ]}
                />
                <AppButton onClick={handleConfirm} className="confirm-btn">
                    Filtra
                </AppButton>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Dropdown
                getPopupContainer={(node) => node.parentElement}
                overlay={renderDropdown}
                trigger="click"
                // don't close dropdown when item is clicked
                visible={visible}
                onVisibleChange={(newState) => setVisible(newState)}
            >
                <div className="filter-selector">
                    <div className="filter-icon">
                        <FiFilter />
                    </div>
                    <div className="filter-label">Filtra documenti</div>
                </div>
            </Dropdown>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_DocsFilter.propTypes = {
    className: PropTypes.string.isRequired,
    onFilter: PropTypes.func,
}

_DocsFilter.defaultProps = {}

// ----------------------------------------------------------------------------

const DocsFilter = styled(_DocsFilter)`
    & {
        /* margin-right: ${({ theme }) => theme.spacing.s}px; */
        .filter-selector {
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 15px;
            border-radius: 6px;
            cursor: pointer;
            background-color: white;

            .filter-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
            .filter-label {
                font-size: 14px;
                font-weight: 400;
                margin-left: 10px;
            }
        }
        .filter-dropdown {
            width: 100%;
            background-color: white;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
            padding: 10px;
            border-radius: 10px;

            display: flex;
            align-items: center;
            flex-direction: column;

            .field {
                width: 100%;
                margin-bottom: 15px;
            }

            .confirm-btn {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default DocsFilter
