import PropTypes from "prop-types"
import React from "react"
import { Route } from "react-router-dom"
import { Switch } from "react-router-dom"
import styled from "styled-components"

import { useStateValue } from "../_shared/context/AppStateStore"
import AdminApp from "./AdminApp"
import UserApp from "./UserApp"

// ----------------------------------------------------------------------------

function _RoutedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Switch>
                <Route path="/admin" component={AdminApp}></Route>
                <Route path="/" component={UserApp}></Route>
            </Switch>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RoutedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_RoutedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const RoutedApp = styled(_RoutedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default RoutedApp
