import { DatePicker } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import FieldLabel from "../../FieldLabel"
import ValidationMessage from "../ValidationMessage"

const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

function _AppDateRangePicker(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { value, onChange, label, className, error, ...pickerProps } = props

    let message
    let invalid

    if (typeof error === "string") {
        message = error
        invalid = !!message
    } else {
        message = error?.message
        invalid = error?.invalid
    }
    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(e) {
        if (e[0] && e[1]) {
            const [start, end] = e
            onChange && onChange({ start, end })
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} ${invalid ? "has-error" : ""}`}>
            {label && <FieldLabel className="label">{label}</FieldLabel>}
            <RangePicker
                className="picker"
                value={value}
                onChange={handleChange}
                format="DD MMM YYYY"
                {...pickerProps}
            ></RangePicker>
            {message && (
                <ValidationMessage className="error-msg">
                    {message}
                </ValidationMessage>
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppDateRangePicker.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.object,
    height: PropTypes.number,
    paddingHorizontal: PropTypes.number,
    borderRadius: PropTypes.number,
}

_AppDateRangePicker.defaultProps = {
    error: { invalid: false, message: null },
}

// ----------------------------------------------------------------------------

const defaultPadding = 23
const defaultBorderRadius = 8
const AppDateRangePicker = styled(_AppDateRangePicker)`
    & {
        max-width: 300px;
        &.has-error {
            .error-msg {
                /* display: block;
                color: ${({ theme }) => theme.colors.error};
                margin-top: 5px;
                font-weight: 500; */
            }
            .ant-picker {
                /* border: 2px solid ${({ theme }) => theme.colors.error}; */
            }
        }
        .label {
        }
        .picker {
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
        }
        .ant-picker {
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
            width: 100%;
            border-radius: ${({ borderRadius }) =>
                borderRadius ?? defaultBorderRadius}px;

            background-color: ${({ theme }) => theme.colors.grey200};
            border: 1px solid ${({ theme }) => theme.colors.grey300};
            margin-bottom: 0;
            box-shadow: none !important;
            padding-left: ${({ paddingHorizontal }) =>
                paddingHorizontal ?? defaultPadding}px;
            padding-right: ${({ paddingHorizontal }) =>
                paddingHorizontal ?? defaultPadding}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default AppDateRangePicker
