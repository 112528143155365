import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import BottomSheet from "../../../../../_shared/components/BottomSheet"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import useFormHandler from "../../../../../_shared/hooks/useFormHandler"

// ----------------------------------------------------------------------------

function _PlantsBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, defaultValues, open, onConfirm, onCancel } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const initialValues = {
        botanicalName: "",
        genus: "",
        species: "",
    }
    const validationSchema = Yup.object().shape({
        botanicalName: Yup.string().nullable().required("Campo obbligatorio"),
        genus: Yup.string().nullable(),
        species: Yup.string().nullable(),
    })

    const { getFieldProps, reset, bulkChange, values, handleSubmit } =
        useFormHandler({
            initialValues,
            validationSchema,
            onSubmit: onConfirm,
        })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        !open && reset()
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        defaultValues && bulkChange({ ...values, ...defaultValues })
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            className={`${className}`}
            snapPoint={80}
            open={open}
            onCancel={onCancel}
            title={defaultValues?.id ? "Modifica utente" : "Crea utente"}
            onConfirm={handleSubmit}
        >
            <AppInput
                label="Nome botanico"
                className="field"
                {...getFieldProps("botanicalName")}
            />
            <AppInput
                label="Genus"
                className="field"
                {...getFieldProps("genus")}
            />
            <AppInput
                label="Specie"
                className="field"
                {...getFieldProps("species")}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PlantsBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    defaultValues: PropTypes.object,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

_PlantsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const PlantsBottomSheet = styled(_PlantsBottomSheet)`
    & {
        .field {
            width: 300px;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default PlantsBottomSheet
