import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { useStateValue } from "../../_shared/context/AppStateStore"
import AuthPage from "./AuthPage"
import LoggedApp from "./LoggedApp"

// ----------------------------------------------------------------------------

function _UserApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ user }] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {user ? <LoggedApp /> : <AuthPage />}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_UserApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_UserApp.defaultProps = {}

// ----------------------------------------------------------------------------

const UserApp = styled(_UserApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default UserApp
