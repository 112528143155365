import { Switch } from "antd"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import BottomSheet from "../../../../../_shared/components/BottomSheet"
import FieldLabel from "../../../../../_shared/components/FieldLabel"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import useFormHandler from "../../../../../_shared/hooks/useFormHandler"

// ----------------------------------------------------------------------------

function _UsersBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onCancel, onConfirm, defaultValues } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const requiredMsg = "Campo obbligatorio"

    const initialValues = {
        email: "",
        id: "",
        password: "",
        businessName: "",
        disabled: false,
        settings: {
            ipAddress: "",
            ruopCode: "",
            regionCode: "",
            stateCode: "",
            countryCode: "",
        },
    }
    const validationSchema = Yup.object().shape({
        id: Yup.string(),
        email: Yup.string()
            .email("L'indirizzo mail deve essere valido")
            .required(requiredMsg),
        password: Yup.string().when("id", {
            is: (id) => !id,
            then: Yup.string().required(requiredMsg),
        }),
        businessName: Yup.string().required(requiredMsg),

        settings: Yup.object().shape({
            ipAddress: Yup.string(),
            ruopCode: Yup.string(),
            regionCode: Yup.string(),
            stateCode: Yup.string(),
            countryCode: Yup.string(),
        }),
        disabled: Yup.bool().nullable(),
    })

    const { values, getFieldProps, handleSubmit, reset, bulkChange } =
        useFormHandler({
            initialValues,
            validationSchema,
            onSubmit: onSubmit,
        })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        !open && reset()
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        defaultValues && bulkChange({ ...values, ...defaultValues })
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function onSubmit(values) {
        onConfirm && onConfirm(values)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            snapPoint={80}
            open={open}
            onCancel={onCancel}
            className={`${className}`}
            title={defaultValues?.id ? "Modifica utente" : "Crea utente"}
            onConfirm={handleSubmit}
        >
            {values?.id && (
                <AppSwitch
                    label="Disabilita utente"
                    className={"field"}
                    {...getFieldProps("disabled")}
                />
            )}
            <AppInput
                {...getFieldProps("email")}
                label="E-mail"
                className="field"
            ></AppInput>
            <AppInput
                {...getFieldProps("businessName")}
                label="Ragione sociale"
                className="field"
            ></AppInput>
            {!defaultValues?.id && (
                <AppInput
                    {...getFieldProps("password")}
                    label="Password"
                    className="field"
                ></AppInput>
            )}

            <AppInput
                {...getFieldProps("settings.ruopCode")}
                label="Codice RUOP"
                className="field"
            ></AppInput>
            <AppInput
                {...getFieldProps("settings.stateCode")}
                label="Codice provincia"
                className="field"
            ></AppInput>
            <AppInput
                {...getFieldProps("settings.regionCode")}
                label="Codice regione"
                className="field"
            ></AppInput>
            <AppInput
                {...getFieldProps("settings.countryCode")}
                label="Codice stato"
                className="field"
            ></AppInput>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_UsersBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    defaultValues: PropTypes.object,
}

_UsersBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const UsersBottomSheet = styled(_UsersBottomSheet)`
    & {
        .field {
            width: 300px;
            margin-bottom: 20px;
        }
        .users-switch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .switch-label,
            .ant-btn {
                display: block;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default UsersBottomSheet

const AppSwitch = ({ label, className, value, onChange, onBlur }) => {
    return (
        <div className={`${className} users-switch`}>
            <FieldLabel className="switch-label">{label}</FieldLabel>
            <Switch
                checked={value}
                onChange={(value) => {
                    onChange && onChange(value)
                    onBlur && onBlur()
                }}
            />
        </div>
    )
}
