import { Popconfirm } from "antd"
import PropTypes from "prop-types"
import React from "react"
import { FiTrash as DeleteButton, FiEdit2 as EditButton } from "react-icons/fi"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import { MdPreview as PreviewButton } from "react-icons/md"
import styled from "styled-components"

import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function _TableActions(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        record,
        onEdit,
        onDelete,
        onPreview,
        extraButtons,
        previewLoading,
        onDuplicate,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {onPreview && (
                <FloatingButton
                    loading={previewLoading}
                    onClick={onPreview}
                    className="icon-button"
                >
                    <PreviewButton className="icn icn-preview" />
                </FloatingButton>
            )}
            {onDuplicate && (
                <FloatingButton
                    onClick={() => onDuplicate(record)}
                    className="icon-button"
                >
                    <HiOutlineDocumentDuplicate className="icn " />
                </FloatingButton>
            )}

            {onEdit && (
                <FloatingButton onClick={onEdit} className="icon-button">
                    <EditButton className="icn icn-edit" />
                </FloatingButton>
            )}
            {onDelete && (
                <Popconfirm
                    title="Sei sicuro di voler procedere all'eliminazione?"
                    placement="left"
                    onConfirm={onDelete}
                >
                    <FloatingButton className="icon-button">
                        <DeleteButton className="icn icn-delete" />
                    </FloatingButton>
                </Popconfirm>
            )}

            {extraButtons}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TableActions.propTypes = {
    className: PropTypes.string.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onPreview: PropTypes.func,
    previewLoading: PropTypes.bool,
    extraButtons: PropTypes.arrayOf(PropTypes.element),
    record: PropTypes.object,
    onDuplicate: PropTypes.func,
}

_TableActions.defaultProps = {}

// ----------------------------------------------------------------------------

const TableActions = styled(_TableActions)`
    & {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .icon-button {
            .icn {
                width: 18px;
                height: 18px;
            }
            .icn-delete {
                stroke: ${({ theme }) => theme.colors.error};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TableActions
