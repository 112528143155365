import "../../index.css"

import PropTypes from "prop-types"
import React from "react"
import {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
    css,
} from "styled-components"

// ----------------------------------------------------------------------------

export default function ThemeProvider(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const theme = {
        spacing: {
            xs: 7,
            s: 20,
            m: 40,
            l: 60,

            formItemHeight: 38,
            screenPadding: 200,
        },

        typography: {},

        shadow: css`
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        `,

        screenSizes: {
            sm: "576px",
            md: "768px",
        },
        colors: {
            primary: "#4A7265",
            primary200: "#84AB9D",
            primary100: "rgba(132, 171, 157, 0.44)",
            secondary: "#FF8989",
            error: "#FF404B",
            error200: "rgba(234, 67, 53, 0.25)",
            error400: "#FF7D94",
            success: "#3DD598",

            light: "#fff",
            dark: "#000",

            grey700: "#92929D",
            grey600: "#696974",
            grey400: "#DCDCE0",
            grey300: "#F1F1F5",
            grey200: "#FAFAFB",
            grey100: "#e2e2ea",
        },
    }

    /* eslint-disable react/prop-types  */
    const GlobalStyle = createGlobalStyle`
         .center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
         .flex-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .flex {
            display: flex;
        }
        .flex-row {
            flex-direction: row;
        }
        .flex-col {
            flex-direction: column;
        }
        .items-center {
            align-items: center;
        }
        .items-statrt {
            align-items: flex-start;
        }
        .items-statrt {
            align-items: flex-end;
        }
        .justify-center {
            justify-content: center;
        }
        .justify-start {
            justify-content: flex-start;
        }
        .justify-end {
            justify-content: flex-end;
        }
        .justify-between {
            justify-content: space-between;
        }

        .h-screen {
            height: 100vh;
        }
        .h-full {
            height: 100%;
        }
        .w-screen {
            width: 100vw;
        }
        .w-full {
            width: 100%;
        }
        .m-w-unset {
            max-width: unset;
        }

        .overflow-hidden {
            overflow:hidden;
        }
        .overflow-x-hidden {
            overflow-x: hidden;
        }
        .overflow-y-auto {
            overflow-y: auto;
        }

        .text-center {
            text-align: center;
        }
    `
    /* eslint-enable react/prop-types  */

    return (
        <StyledComponentsThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </StyledComponentsThemeProvider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

ThemeProvider.defaultProps = {}
