import PropTypes from "prop-types"
import React from "react"
import { AiOutlineClose as CloseIcon } from "react-icons/ai"
import styled from "styled-components"

import Button from "../AppButton"
import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function _BottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        open,
        children,
        onCancel,
        onConfirm,
        title,
        renderExtraButtons,
        hideButtons,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    if (open) {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = ""
    }

    return (
        <div className={`${props.className}${open ? " open" : ""}`}>
            <div className="mask" />

            <div className="sheet">
                <div className="header">
                    <div className="title">{(title && title) || ""}</div>

                    <FloatingButton className="close-icn" onClick={onCancel}>
                        <CloseIcon />
                    </FloatingButton>
                </div>

                {open && <div className="sheet-content">{children}</div>}

                {(onConfirm || onCancel) && (
                    <div className="footer">
                        {renderExtraButtons && (
                            <div className="extra-btns">
                                {renderExtraButtons()}
                            </div>
                        )}

                        {onCancel && !hideButtons && (
                            <Button
                                onClick={onCancel}
                                uxType="secondary"
                                className="cancel-btn"
                            >
                                Annulla
                            </Button>
                        )}
                        {onConfirm && !hideButtons && (
                            <Button
                                loading={props.loading}
                                className="confirm-btn"
                                onClick={!props.loading && onConfirm}
                                uxType="primary"
                                htmlType="button"
                            >
                                Salva
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_BottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
    children: PropTypes.any,
    onCancel: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    snapPoint: PropTypes.oneOf([40, 50, 80, 90, 100]),
    renderExtraButtons: PropTypes.func,
    loading: PropTypes.bool,
    onConfirm: PropTypes.func,
    hideButtons: PropTypes.bool,
}

_BottomSheet.defaultProps = {
    open: () => {},
    snapPoint: 40,
    renderExtraButtons: () => {},
}

// ----------------------------------------------------------------------------

const BottomSheet = styled(_BottomSheet)`
    & {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        overflow-x: hidden;

        z-index: 998;

        ::-webkit-scrollbar {
            display: none;
        }

        .sheet {
            position: fixed;
            top: ${(props) => {
                return (
                    100 -
                    (props?.snapPoint ?? _BottomSheet.defaultProps.snapPoint)
                )
            }}vh;
            left: 0;

            display: flex;
            flex-direction: column;

            box-sizing: border-box;
            padding: 40px 40px 20px 40px;
            border-radius: 20px 20px 0 0;

            width: 100vw;
            height: ${(props) =>
                props?.snapPoint ?? _BottomSheet.defaultProps.snapPoint}vh;

            background-color: ${(props) => props.theme.colors.light};
            z-index: 999;

            overflow: auto;

            ::-webkit-scrollbar {
                display: none;
            }

            .header {
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;

                .title {
                    flex: 1;

                    font-size: 18px;
                    font-weight: 600;
                    color: ${(props) => props.theme.colors.dark};
                }

                .close-icn {
                    margin-left: 10px;

                    /* width: 16px;
                    height: 16px; */

                    cursor: pointer;

                    transition: all 0.2s ease;
                }
            }

            .sheet-content {
                flex: 1;
            }

            .footer {
                margin-top: 20px;

                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .extra-btns {
                    margin-right: 10px;
                }

                .confirm-btn {
                    margin-left: 10px;
                    height: 36px;
                }
                .cancel-btn {
                    height: 36px;
                }
            }
        }

        .mask {
            position: fixed;
            opacity: 0;
            transition: opacity 0.1s ease 0.3s;
            z-index: 998;
        }
    }

    &.open {
        transform: translateY(0);

        .mask {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scale(1);
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(5px);
        }

        /* MOBILE */

        @media (max-width: 576px) {
            .drawer {
                min-width: 100vw;
                width: 100vw;
                max-width: 100vw;
                height: 80vh;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default BottomSheet
