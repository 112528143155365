/* eslint-disable no-console */

import { useEffect, useReducer, useRef } from "react"

import * as api from "../../../../api"
import reducer, { usePaginatedRestResourceActions as actions } from "./_reducer"

export default function usePaginatedRestResource(
    resourceUrl,
    token,
    options = {}
) {
    // ----------------------------------------------------------------------------
    // Inital definitions
    // ----------------------------------------------------------------------------

    const {
        defaultFilters = {},
        defaultSort = {},
        defaultPageSize = 20,

        fetchOnMount = true,
        waitFor = [],
        refetchOn = [],
        cacheId,
        debug = false,
    } = options
    const waitForHasItems = waitFor?.length >= 1
    const refetchOnHasItems = refetchOn?.length >= 1

    const initialState = {
        resource: null,
        filters: defaultFilters || null,
        pagination: { pageSize: defaultPageSize, current: 1 },
        sort: defaultSort,
        isLoading: false,
        error: null,
        isInitialFetch: true,
        waitForFulfilled: false,
        shouldRefetch: false,
        // 'error' | 'success' | 'pending'
        initialHydrationStatus: cacheId ? "pending" : "error",
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    // debug && console.log(state?.filters)
    // debug && console.log(state)

    const previousFilters = useRef({})
    const previousRefetchConditions = useRef([])
    const previousPagination = useRef()
    const previousSort = useRef()

    // counter to establish whether incoming resource is from last api call
    const callCounter = useRef(0)

    // ----------------------------------------------------------------------------
    // Effects
    // ----------------------------------------------------------------------------

    // useEffect(() => {
    //     cacheId && hydrateFromCache()

    //     // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        if (waitForHasItems && !state.waitForFulfilled) {
            const condition = waitFor.every((i) => !!i)
            debug &&
                console.log(
                    `[EFFECT: ckeck waitFor]: checked values [${waitFor}] with results '${condition}'`
                )
            condition && dispatch(actions.watiForFulfilled(defaultFilters))
        } else {
            !state.waitForFulfilled && dispatch(actions.watiForFulfilled())
        }
        // eslint-disable-next-line
    }, [...waitFor])

    useEffect(() => {
        if (refetchOnHasItems) {
            const itemsDidChange = !refetchOn.every(
                (item, index) => previousRefetchConditions[index] === item
            )
            if (itemsDidChange && state.waitForFulfilled) {
                debug &&
                    console.log("[EFFECT: check refetchOn] triggering refetch")
                dispatch(actions.shouldRefetch(defaultFilters))
            }
        }
        // eslint-disable-next-line
    }, [...refetchOn])

    useEffect(() => {
        if (state.initialHydrationStatus !== "pending") {
            const { shouldFetch, reason } = getShouldFetch()
            debug && console.log("shouldFetch", shouldFetch)
            if (shouldFetch) {
                debug && console.log(`fetching beacuse ${reason}`)
                fetchResource()
            }
        }
        // eslint-disable-next-line
    }, [
        state.initialHydrationDone,
        state.waitForFulfilled,
        state.shouldRefetch,
        state.filters,
        state.sort,
        state.pagination,
    ])

    // ----------------------------------------------------------------------------
    // Hook functions
    // ----------------------------------------------------------------------------

    // function hydrateFromCache() {
    //     const cachedResource = localStorage.getItem(cacheId)
    //     try {
    //         const json = JSON.parse(cachedResource)
    //         dispatch(actions.hydrate(json))
    //     } catch {
    //         dispatch(actions.hydrate(null))
    //     }
    // }

    function getShouldFetch() {
        // check if conditions for fetching have been met
        let waitForFulfilled = state.waitForFulfilled
        // check if filters changed
        const filtersHaveChanged = checkFiltersChanged(
            state.filters,
            previousFilters.current
        )
        // check if pagination changed
        const paginationHasChanged = checkPaginationChanged()
        // check if sort changed
        const sortHasChanged = checkSortChanged()

        // compose items
        if (waitForFulfilled) {
            if (state.isInitialFetch) {
                dispatch(actions.initialFetchDone())
                return {
                    shouldFetch: fetchOnMount,
                    reason: "fetchOnMount triggers fethc",
                }
            }
            if (paginationHasChanged) {
                return {
                    shouldFetch: true,
                    reason: "pagination params changed",
                }
            }
            if (sortHasChanged) {
                return { shouldFetch: true, reason: "sort params changed" }
            }
            if (filtersHaveChanged) {
                return { shouldFetch: true, reason: "filters params changed" }
            }
            if (state.shouldRefetch) {
                return {
                    shouldFetch: true,
                    reason: "refetchOn triggered change",
                }
            }
        }
        return { shouldFetch: false, reson: "no checks triggered" }
    }

    function checkSortChanged() {
        const orderChanged = state?.sort?.order !== previousSort?.current?.order
        const keyChanged =
            state?.sort?.columnKey !== previousSort?.current?.columnKey

        if (orderChanged || keyChanged) {
            previousSort.current = state.sort
            return true
        }
        return false
    }

    function checkPaginationChanged() {
        debug && console.log(previousPagination.current, state.pagination)
        if (!previousPagination.current || !state.pagination) {
            // before the first request, this end up being an empty object, which causes the data
            // to re-download after pagination is first acquired. We only want to re-fetch if
            // pagination actually changed
            previousPagination.current = state.pagination
            return false
        }

        const pageChanged =
            state?.pagination?.current !== previousPagination?.current?.current
        const pageSizeChanged =
            state?.pagination?.pageSize !==
            previousPagination?.current?.pageSize

        if (pageChanged || pageSizeChanged) {
            previousPagination.current = state.pagination
            return true
        }
        return false
    }

    function checkFiltersChanged(filters, prevFilters) {
        // debug && console.table({ filters, prevFilters })
        previousFilters.current = filters
        if (filters && prevFilters) {
            const filtersHaveChanged = !(
                JSON.stringify(filters) === JSON.stringify(prevFilters)
            )
            return filtersHaveChanged
        }
        return true
    }

    function resolveResponse(response, currentCounter, error) {
        if (currentCounter === callCounter.current) {
            if (error) {
                dispatch(actions.fetchFail(error))
            } else {
                dispatch(actions.fetchDone(response))
                if (cacheId) {
                    localStorage.setItem(cacheId, JSON.stringify(response))
                }
            }
        }
    }

    async function fetchResource() {
        callCounter.current = callCounter.current + 1
        const currentCounter = callCounter.current
        try {
            debug && console.log("[FETCHER FUNTCTION]: starting to fetch")

            let url = `${resourceUrl}`

            const requestOptions = {
                filters: state?.filters ?? {},
                pageNumber: state?.pagination?.current,
                pageSize: state?.pagination?.pageSize,
                sort: {
                    key: state?.sort?.columnKey,
                    order: state?.sort?.order,
                },
            }

            dispatch(actions.fetchStart())
            const newResource = await api.getResourceList(
                url,
                token,
                requestOptions
            )
            resolveResponse(newResource, currentCounter)
        } catch (error) {
            resolveResponse(null, currentCounter, error)
        }
    }

    function handleFiltersChange(newFilters = {}) {
        dispatch(actions.changeFilters(newFilters))
    }

    function handlePaginationChange(newPagination = {}) {
        dispatch(
            actions.changePagination({
                current: newPagination.current ?? 1,
                pageSize: newPagination.pageSize,
            })
        )
    }

    function handleSortChange(newSort = {}) {
        dispatch(actions.changesort(newSort))
    }

    // ----------------------------------------------------------------------------
    // Hook return
    // ----------------------------------------------------------------------------

    const resourceMetaData = {
        pagination: state?.pagination,
        count: state?.count,
        filters: state?.filters,
        error: state?.error,
        sort: state?.sort,
        loading: state.isLoading,
    }

    const resourceManager = {
        fetchResource: fetchResource,
        onFiltersChange: handleFiltersChange,
        onPaginationChange: handlePaginationChange,
        onSortChange: handleSortChange,
    }

    return [state.resource, state.status, resourceMetaData, resourceManager]
}
