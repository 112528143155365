import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { useTheme } from "styled-components"

import { createLot, deleteLot, updateLot } from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import AppTable from "../../../../_shared/components/Table"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import LotsModal from "./LotsModal"

// ----------------------------------------------------------------------------

function _Lots(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [lots, , { pagination }, { fetchResource, onPaginationChange }] =
        usePaginatedRestResource("/lots", token, { debug: false })

    const [settings] = useRestResource("/user-settings/me", token)

    const { businessCenters } = settings ?? {}

    const [selectedItem, setSelectedItem] = useState(false)

    const theme = useTheme()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderNewButton() {
        return (
            <div className="new-button-wrapper">
                <AppButton
                    className="button"
                    uxType="plain"
                    onClick={() => setSelectedItem({})}
                >
                    Nuovo lotto
                </AppButton>
            </div>
        )
    }

    function hideModal() {
        setSelectedItem(null)
    }

    async function handleSubmit(values) {
        try {
            values?.id
                ? await updateLot(values.id, values, token)
                : await createLot(values, token)
            message.success("Operazione completata con successo")
            fetchResource()
            hideModal()
        } catch (e) {
            if (e.errorKey === "DUPLICATE_CODE") {
                message.error(
                    "Due lotti dello stesso centro non possono condividere lo stesso codice."
                )
            } else {
                // eslint-disable-next-line
                console.log(e)
                message.error("Operazione fallita")
            }
        }
    }

    function handleLotsDelete(lots) {
        async function run() {
            for (const lot of lots) {
                // eslint-disable-next-line
                await deleteLot(lot, token).catch((e) => console.log(e))
            }

            fetchResource()
            message.success("Lotti eliminati con successo")
        }

        Modal.confirm({
            title: "Sei sicuro di voler eliminare i lotti selezionati?",
            onOk: run,
        })
    }

    function renderButton(row) {
        return (
            <AppButton uxType="plain" onClick={() => setSelectedItem(row)}>
                Modifica
            </AppButton>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderNewButton()}
            <AppTable
                dataSource={lots}
                columns={getColumns(renderButton)}
                onDelete={handleLotsDelete}
                onRow={(record) => ({
                    // onClick: () => setSelectedItem(record),
                    style: { cursor: "pointer" },
                })}
                rowHoverColor={theme.colors.grey300}
                pagination={pagination}
                onChange={(p) => {
                    onPaginationChange(p)
                }}
            />
            <LotsModal
                onCancel={hideModal}
                visible={!!selectedItem}
                defaultValues={selectedItem}
                onConfirm={handleSubmit}
                businessCenters={businessCenters}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Lots.propTypes = {
    className: PropTypes.string.isRequired,
}

_Lots.defaultProps = {}

// ----------------------------------------------------------------------------

const Lots = styled(_Lots)`
    & {
        padding: ${({ theme }) =>
            `${theme.spacing.l}px ${theme.spacing.screenPadding}px`};

        .new-button-wrapper {
            display: flex;
            justify-content: flex-end;
            .button {
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Lots

function getColumns(renderButton) {
    return [
        {
            title: "Codice lotto",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Descrizione",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Numero centro aziendale",
            key: "center",
            render: (row) =>
                [row?.businessCenter?.number, row?.businessCenter?.name].join(
                    " - "
                ),
        },
        {
            key: "action",
            render: renderButton,
        },
    ]
}
