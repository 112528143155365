import { AutoComplete, Input, Select } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { FaPlus as AddIcon } from "react-icons/fa"
import { FiTrash2 as DeleteIcon } from "react-icons/fi"
import styled from "styled-components"

import { useStateValue } from "../../context/AppStateStore"
import usePaginatedRestResource from "../../hooks/usePaginatedRestResource"
import ValidationMessage from "../FormItems/ValidationMessage"

// ----------------------------------------------------------------------------

function _PlantSelector(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onChange, value, onBlur, error, lots, businessCenters } =
        props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const defaultPlant = {
        botanicalName: "",
        trackingCode: "",
        businessCenter: null,
        lot: null,
    }

    const [newPlant, setNewPlant] = useState(defaultPlant)

    const [{ token }] = useStateValue()

    const [plants] = usePaginatedRestResource("/plants", token, {
        waitFor: [token, newPlant.botanicalName],
        refetchOn: [token, newPlant.botanicalName],
        defaultFilters: {
            botanicalName: newPlant.botanicalName?.toLowerCase(),
        },
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const plantSuggestions = useMemo(() => {
        return (
            plants
                ?.filter(
                    (r, i, s) =>
                        s.findIndex(
                            (p) => p.botanicalName === r.botanicalName
                        ) === i
                )
                ?.map((plant) => ({
                    label: plant.botanicalName,
                    value: plant.botanicalName,
                })) ?? []
        )
    }, [plants])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        const changeHandler = (trackingCode) =>
            setNewPlant({ ...newPlant, trackingCode })
        const lot = lots?.find((lot) => lot.id === newPlant.lot)
        const businessCenter = businessCenters?.find(
            (center) => center.id === newPlant.businessCenter
        )
        const newCode =
            lot && businessCenter && `${businessCenter.number}-${lot.code}`
        newCode && changeHandler(newCode)
        // eslint-disable-next-line
    }, [newPlant.lot, newPlant.businessCenter])

    useEffect(() => {
        setNewPlant({ ...newPlant, lot: null, trackingCode: "" })
        // eslint-disable-next-line
    }, [newPlant.businessCenter])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(field) {
        return (e) => setNewPlant({ ...newPlant, [field]: e })
    }

    function handleAddPlant(e) {
        e.preventDefault()
        onBlur()
        if (newPlant.botanicalName && newPlant.trackingCode) {
            onChange && onChange([...value, newPlant])
            setNewPlant({ botanicalName: "", trackingCode: "" })
        }
    }

    function handleDeletePlant(plant) {
        return () => {
            onChange(
                value.filter((p) => p.botanicalName !== plant.botanicalName)
            )
        }
    }

    function renderPlants() {
        return value?.map((plant) => {
            const foundCenter = businessCenters?.find(
                (center) => center.id === plant.businessCenter
            )
            const foundLot = lots?.find((lot) => lot.id === plant.lot)
            return (
                <div
                    key={`${plant?.trackingCode}/${plant?.botanicalName}`}
                    className="plant flex flex-row items-center"
                >
                    <div className="inputs-wrapper">
                        <div className="name flex items-center justify-start">
                            <span>{plant?.botanicalName}</span>
                        </div>
                        <div className="code flex items-center justify-start">
                            <span>{foundCenter?.name}</span>
                        </div>
                        <div className="code flex items-center justify-start">
                            <span>{foundLot?.description}</span>
                        </div>
                        <div className="code flex items-center justify-start">
                            <span>{plant?.trackingCode}</span>
                        </div>
                    </div>

                    <div
                        className="icon flex-center"
                        onClick={handleDeletePlant(plant)}
                    >
                        <DeleteIcon />
                    </div>
                </div>
            )
        })
    }

    function getBusinessCentersOptions() {
        return businessCenters?.map((center) => ({
            label: [center?.number, center?.name]?.join(" - "),
            value: center?.id,
        }))
    }

    function getLotsOptions() {
        return lots
            ?.filter(
                (lot) => lot?.businessCenter?.id === newPlant?.businessCenter
            )
            ?.map((lot) => ({ label: lot.description, value: lot.id }))
    }

    function renderAddPlant() {
        return (
            <form
                onSubmit={handleAddPlant}
                className="add-plant plant flex flex-row items-center"
            >
                <div className="inputs-wrapper">
                    <div className="name">
                        <AutoComplete
                            className="autocomplete first"
                            value={newPlant.botanicalName}
                            onChange={handleChange("botanicalName")}
                            placeholder="Nome botanico"
                            options={plantSuggestions}
                            onSelect={handleChange("botanicalName")}
                        ></AutoComplete>
                    </div>
                    <div className="code">
                        <Select
                            className="input select"
                            value={newPlant.businessCenter}
                            onChange={handleChange("businessCenter")}
                            placeholder="Centro aziendale"
                            options={getBusinessCentersOptions()}
                        ></Select>
                    </div>
                    <div className="code">
                        <Select
                            className="input select"
                            value={newPlant.lot}
                            onChange={handleChange("lot")}
                            placeholder="Lotto"
                            options={getLotsOptions()}
                            disabled={!newPlant.businessCenter}
                        ></Select>
                    </div>
                    <div className="code">
                        <Input
                            className="input select"
                            value={newPlant.trackingCode}
                            onChange={(e) =>
                                handleChange("trackingCode")(e.target.value)
                            }
                            placeholder="Codice tracciabilità"
                        ></Input>
                    </div>
                </div>

                <div className="icon flex-center" onClick={handleAddPlant}>
                    <AddIcon />
                </div>
            </form>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} w-full`}>
            <div className="selector w-full">
                {renderPlants()}
                {renderAddPlant()}
                {error && <ValidationMessage>{error}</ValidationMessage>}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PlantSelector.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func,
    businessCenters: PropTypes.array,
    lots: PropTypes.array,
    error: PropTypes.any,
    onBlur: PropTypes.func,
}

_PlantSelector.defaultProps = {}

// ----------------------------------------------------------------------------

const PlantSelector = styled(_PlantSelector)`
    & {
        .inputs-wrapper {
            flex: 1;
            display: flex;
            height: 100%;
        }
        .selector {
            .plant {
                border: 1px solid ${({ theme }) => theme.colors.grey400};
                height: 40px;
                border-radius: 8px;
                margin-bottom: 10px;
                overflow: hidden;

                .name,
                .code {
                    padding: 0px 10px;
                    box-sizing: border-box;
                }
                .name {
                    /* flex: 2; */
                    width: 40%;
                    max-width: 40%;
                    min-width: 40%;
                    border-right: 1px solid
                        ${({ theme }) => theme.colors.grey400};
                    background: ${({ theme }) => theme.colors.grey300};
                    height: 100%;
                }
                .code {
                    /* flex: 1; */
                    width: 20%;
                    max-width: 20%;
                    min-width: 20%;
                    border-right: 1px solid
                        ${({ theme }) => theme.colors.grey400};
                    background: ${({ theme }) => theme.colors.grey300};
                    height: 100%;
                }
                .icon {
                    width: 40px;
                    /* width: 40px; */
                    background: ${({ theme }) => theme.colors.grey300};
                    height: 100%;
                    cursor: pointer;
                }

                &.add-plant {
                    overflow: visible;
                    margin-top: 20px;
                    .name,
                    .code,
                    .icon {
                        background-color: transparent;
                        padding: 0;
                    }
                    .autocomplete {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        margin: 0;
                        border: none;

                        .ant-select-selector {
                            height: 100%;
                            border: none;

                            input {
                                height: 100%;
                            }
                        }
                        .ant-select-selection-placeholder {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }

                        &.first {
                            .ant-select-selector {
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }
                        }
                    }
                    .select {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        margin: 0;

                        .ant-select-selector,
                        .ant-select-selection-item {
                            height: 100%;
                            border: none;
                            padding: 0;
                            margin: 0;
                            padding: 0px 10px;
                        }

                        .ant-select-selection-item,
                        .ant-select-selection-placeholder {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .ant-input {
                        border: none;
                        padding: 0px 10px;
                    }
                }
            }
        }
        .buttons {
            margin-top: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default PlantSelector
