import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../../api"
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg"
import { useStateValue } from "../../../../context/AppStateStore"
import useOnClickOutside from "../../../../hooks/useOnClickOutside"
import AppInput from "../../../FormItems/AppInput"
import SearchModal from "../../../SearchModal"

// ----------------------------------------------------------------------------

function _SearchBox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [expanded, setExpanded] = useState()
    const [search, setSearch] = useState()
    const [finalSearchValue, setSearchValue] = useState()

    const [filteredDocuments, setFilteredDocuments] = useState(null)

    const ref = useRef()
    const inputRef = useRef()
    useOnClickOutside(ref, () => setExpanded(false))

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleClick() {
        setExpanded(true)
        inputRef?.current?.focus()
    }

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            const documentsResponse = await api.getResourceList(
                "/movements",
                token,
                {
                    filters: {
                        number: search,
                    },
                }
            )

            setFilteredDocuments(documentsResponse?.rows ?? [])

            setSearchValue(search)
        } catch (error) {
            console.error(error)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    // const isExpanded = !!(expanded || search)
    const isExpanded = !!expanded

    return (
        <div ref={ref} className={`${className} ${isExpanded && "expanded"}`}>
            <form onSubmit={handleSubmit}>
                {isExpanded && (
                    <AppInput
                        ref={inputRef}
                        className="input"
                        id="seach-input"
                        value={search}
                        onChange={setSearch}
                    />
                )}
            </form>
            <div className="icon" onClick={handleClick}>
                <SearchIcon />
            </div>
            <SearchModal
                visible={!!finalSearchValue}
                onClose={() => setSearchValue("")}
                searchValue={finalSearchValue}
                documents={filteredDocuments}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SearchBox.propTypes = {
    className: PropTypes.string.isRequired,
}

_SearchBox.defaultProps = {}

// ----------------------------------------------------------------------------

const SearchBox = styled(_SearchBox)`
    & {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            transition: right 200ms ease;
        }
        .input {
            pointer-events: none;
            opacity: 0;
            transition: opacity 200ms ease;
            width: 200px;

            background-color: transparent;
            border-color: ${({ theme }) => theme.colors.grey700};

            .app-input {
                padding-right: 50px;
                color: ${({ theme }) => theme.colors.grey700};
            }
        }

        &.expanded {
            .icon {
                right: ${({ theme }) => theme.spacing.s}px;
            }

            .input {
                opacity: 1;
                pointer-events: initial;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default SearchBox
