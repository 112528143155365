import { Table as AntTable } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import Checkbox from "../FormItems/Checkbox"
import Pagination from "./Pagination"
import TableTop from "./TableTop"
import TopParams from "./TopParams"

// ----------------------------------------------------------------------------

function _Table(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        onDelete,
        onPrint,
        onFilter,
        sort,
        onSortChange,
        hideHeader,
        hidePagination,
        renderTopRightSection,
        ...tableProps
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [rowSelection, setRowSelection] = useState([])

    const { pagination, onChange } = tableProps

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const getIsRowChecked = (id) => {
        return rowSelection?.includes(id)
    }

    const toggleRow = (id) => {
        return () => {
            const isSelected = getIsRowChecked(id)
            if (isSelected) {
                setRowSelection(rowSelection.filter((key) => key !== id))
            } else {
                setRowSelection([...rowSelection, id])
            }
        }
    }

    function getKey(record) {
        return props.rowKey ? props.rowKey(record) : record.id
    }

    function handlePaginationChange(pagination) {
        onChange && onChange(pagination)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {!hideHeader && (
                <TopParams
                    pagination={pagination}
                    sort={sort}
                    onSortChange={onSortChange}
                    onChange={handlePaginationChange}
                    onFilter={onFilter}
                    renderTopRightSection={renderTopRightSection}
                />
            )}
            <TableTop
                selectedKeys={rowSelection}
                open={rowSelection.length > 0}
                onDelete={
                    onDelete &&
                    (() => {
                        onDelete(rowSelection)
                        setRowSelection([])
                    })
                }
                onPrint={onPrint && (() => onPrint(rowSelection))}
            />
            <AntTable
                rowSelection={{
                    hideSelectAll: true,
                    selectedRowKeys: rowSelection,
                    onChange: (keys) => {
                        setRowSelection(keys)
                    },
                    renderCell: (checked, record) => (
                        <Checkbox
                            className="custom-checkbox"
                            checked={getIsRowChecked(getKey(record))}
                            onChange={toggleRow(getKey(record))}
                        />
                    ),
                    getCheckboxProps: () => ({
                        onClick: (e) => e.stopPropagation(),
                    }),
                }}
                rowKey={props.rowKey ?? ((i) => i.id)}
                className="table"
                pagination={pagination}
                bordered={false}
                {...tableProps}
            />
            {!hidePagination && (
                <Pagination
                    {...pagination}
                    onChange={(newPagination) =>
                        newPagination.current !== pagination.current &&
                        onChange &&
                        onChange(newPagination)
                    }
                    className="pagination"
                />
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Table.propTypes = {
    className: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    onPrint: PropTypes.func,
    sort: PropTypes.any,
    onSortChange: PropTypes.func,
    hideHeader: PropTypes.bool,
    hidePagination: PropTypes.bool,
    onFilter: PropTypes.func,
    rowKey: PropTypes.func,
    renderTopRightSection: PropTypes.func,
}

_Table.defaultProps = {}

// ----------------------------------------------------------------------------

const Table = styled(_Table)`
    & {
        .table-top {
        }

        .custom-checkbox {
            padding: 0px 10px;
        }

        .table {
            .ant-table {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                overflow: hidden;

                .ant-table-content {
                    overflow-x: auto;
                }
            }
            th {
                background-color: ${({ theme }) => theme.colors.primary100};
                text-transform: uppercase;
                ::before {
                    display: none;
                }
            }

            td {
                border: none !important;
            }

            .ant-table-row {
                background-color: white;

                &:hover td {
                    background-color: ${({ rowHoverColor }) =>
                        rowHoverColor ?? "white"};
                }
            }

            .ant-table-row-selected {
                td {
                    background-color: ${({ theme }) => theme.colors.primary200};
                    color: ${({ theme }) => theme.colors.light};
                }

                &:hover {
                    td {
                        background-color: ${({ theme }) =>
                            theme.colors.primary200};
                        color: ${({ theme }) => theme.colors.light};
                    }
                }
            }
            .ant-pagination {
                display: none;
            }
            .ant-checkbox-inner::after {
                background-color: ${({ theme }) => theme.colors.primary};
            }
        }
        .pagination {
            margin-top: ${({ theme }) => theme.spacing.s}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Table
