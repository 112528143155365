import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import AdminMenu from "./Menu"

// ----------------------------------------------------------------------------

function _AdminLayout(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, children } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <AdminMenu className="menu"></AdminMenu>
            <div className="content-wrapper">
                <main>{children}</main>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AdminLayout.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.object,
}

_AdminLayout.defaultProps = {}

// ----------------------------------------------------------------------------

const AdminLayout = styled(_AdminLayout)`
    & {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        .content-wrapper {
            flex: 1;
            max-height: 100vh;
            main {
                width: 100%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 20px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AdminLayout
