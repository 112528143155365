import PropTypes from "prop-types"
import React from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import Layout from "../../../_shared/components/Layout"
import Documents from "./Documents"
import Inventory from "./Inventory"
import Lots from "./Lots"
import Settings from "./Settings"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Layout className={`${className}`}>
            <Switch>
                <Route exact path="/" component={Documents} />
                <Route exact path="/magazzino" component={Inventory} />
                <Route exact path="/impostazioni" component={Settings} />
                <Route exact path="/lotti" component={Lots} />
            </Switch>
        </Layout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
