import PropTypes, { string } from "prop-types"
import React, { useMemo } from "react"
import styled from "styled-components"

import { getDocumentColumns } from "../../../RoutedApp/UserApp/LoggedApp/Documents"
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import { getMockDocuments } from "../../toolkit/fake"
import AppButton from "../AppButton"
import Modal from "../Modal"
import Table from "../Table"

// ----------------------------------------------------------------------------

function _SearchModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, visible, documents, onClose, searchValue } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const data = useMemo(() => [getMockDocuments()[0]], [])

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderPreview(row) {
        return <AppButton uxType="link">Preview</AppButton>
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    const columns = getDocumentColumns(renderPreview, () => {})

    return (
        <Modal visible={visible} onClose={onClose} className={`${className}`}>
            <div className="search-modal-content">
                <div className="top-bar">
                    <div className={"search"}>
                        <span className="label">Documento cercato: </span>
                        <span className="value">{searchValue}</span>
                    </div>
                    <div className="close-icon" onClick={onClose}>
                        <CloseIcon />
                    </div>
                </div>
                <Table
                    hideHeader
                    hidePagination
                    dataSource={documents}
                    columns={columns.slice(0, columns.length - 1)}
                />
            </div>
        </Modal>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SearchModal.propTypes = {
    className: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    searchValue: string,
    documents: PropTypes.array,
}

_SearchModal.defaultProps = {}

// ----------------------------------------------------------------------------

const SearchModal = styled(_SearchModal)`
    & {
        z-index: 1000;

        .search-modal-content {
            width: 80vw;
            max-height: 80vh;
            padding: ${({ theme }) => theme.spacing.m}px;
            overflow: auto;

            .top-bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: ${({ theme }) => theme.spacing.m}px;

                .close-icon {
                    cursor: pointer;
                }
            }
            .search {
                display: flex;
                align-items: center;
                .label {
                    font-size: 24px;
                    font-weight: 600;
                    margin-right: 20px;
                    line-height: 30px;
                }
                .value {
                    font-size: 16px;
                    color: ${({ theme }) => theme.colors.grey700};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default SearchModal
