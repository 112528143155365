import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import Modal from "../../../../../_shared/components/Modal"
import useFormHandler from "../../../../../_shared/hooks/useFormHandler"

// ----------------------------------------------------------------------------

function _LotsModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        visible,
        onConfirm,
        onCancel,
        defaultValues,
        defaultSelectedBusinessCenter,
        businessCenters,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const initialValues = {
        code: "",
        description: "",
        businessCenter: "",
        user: "",
    }
    const msg = "Campo obbligatorio"
    const validationSchema = Yup.object().shape({
        code: Yup.string().required(msg),
        description: Yup.string().required(msg),
        businessCenter: Yup.string().nullable().required(msg),
        user: Yup.string(),
    })

    const { handleSubmit, getFieldProps, values, bulkChange, reset } =
        useFormHandler({
            initialValues,
            validationSchema,
            onSubmit: handleConfirm,
        })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        !visible && reset()
        // eslint-disable-next-line
    }, [visible])

    useEffect(() => {
        defaultValues &&
            bulkChange({
                ...values,
                ...{
                    ...defaultValues,
                    businessCenter: defaultValues?.businessCenter?.id,
                },
            })
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm(values) {
        onConfirm && onConfirm(values)
    }

    function getBusinessCenterOptions() {
        return businessCenters?.map((center) => ({
            label: `${center?.name} (${center.number})`,
            value: center?.id,
        }))
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Modal visible={visible} onCancel={onCancel} className={`${className}`}>
            <form onSubmit={handleSubmit} className="wrapper">
                <span className="title">
                    {defaultValues?.id ? "Modifica lotto" : "Crea nuovo lotto"}
                </span>
                <AppInput
                    className="field"
                    label="Codice univoco"
                    {...getFieldProps("code")}
                />
                <AppInput
                    className="field"
                    label="Descrizione"
                    {...getFieldProps("description")}
                />
                <AppSelect
                    label="Centro aziendale"
                    className="field"
                    {...getFieldProps("businessCenter")}
                    options={getBusinessCenterOptions()}
                />
                <div className="buttons">
                    <AppButton
                        htmlType="button"
                        uxType="delete"
                        onClick={onCancel}
                    >
                        Annulla
                    </AppButton>
                    <AppButton>Salva</AppButton>
                </div>
            </form>
        </Modal>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LotsModal.propTypes = {
    className: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    defaultSelectedBusinessCenter: PropTypes.string,
    businessCenters: PropTypes.array,
    defaultValues: PropTypes.object,
}

_LotsModal.defaultProps = {}

// ----------------------------------------------------------------------------

const LotsModal = styled(_LotsModal)`
    & {
        .modal-sheet {
            border-radius: 0px !important;
        }
        .wrapper {
            padding: 20px;
            max-height: 500px;
            overflow: auto;
            position: relative;

            .buttons {
                display: flex;
                justify-content: flex-end;

                button {
                    height: 30px;
                    padding: 10px 10px;
                }

                button:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        .title {
            display: block;
            font-weight: 500;
            margin-bottom: 30px;
        }
        .field {
            width: 300px;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default LotsModal
