import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as Arrow } from "../../../assets/icons/dropdown.svg"
import DocsFilter from "../../FormItems/DocsFilter"
import TableSelect from "../../FormItems/TableSelect"

// ----------------------------------------------------------------------------

function _TopParams(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        pagination,
        sort,
        onChange,
        onFilter,
        renderTopRightSection,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderPageSizeSelector({ label, value }) {
        return (
            <div className="page-size-select-inner">
                <span className="selector-text">
                    <span className="label">Mostra: </span>
                    <span className="value">{value}</span>
                </span>
                {arrowDown}
            </div>
        )
    }

    function renderSortSelector({ label, value }) {
        return (
            <div className="sort-select-inner">
                <div className="selector-text">
                    <span className="label">Ordine:</span>
                    <span className="value">{label}</span>
                </div>
                <div className="selector-arrow">{arrowDown}</div>
            </div>
        )
    }

    function renderFilters() {
        return (
            <div className="filter-selector">
                <DocsFilter onFilter={onFilter} />
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const arrowDown = (
        <div className="arrow-down">
            <Arrow />
        </div>
    )

    const pageSizeOptions = [
        {
            label: "Mostra 10",
            value: 10,
        },
        {
            label: "Mostra 20",
            value: 20,
        },
    ]

    const sortOptions = [
        { label: "Ultima importazione", value: "new" },
        { label: "Luogo Destinazione", value: "dest" },
        { label: "Luogo Origine", value: "origin" },
    ]

    return (
        <div className={`${className}`}>
            <div className="left">
                <h1 className="table-title">Tutti i record</h1>
                <TableSelect
                    className="page-size-select"
                    options={pageSizeOptions}
                    value={pagination?.pageSize}
                    renderSelector={renderPageSizeSelector}
                    onChange={(value) =>
                        onChange({ ...pagination, pageSize: value })
                    }
                />
            </div>

            {renderTopRightSection && (
                <div className="right">{renderTopRightSection()}</div>
            )}
            {/* <div className="right">
                <TableSelect
                    className="sort-select"
                    options={sortOptions}
                    value={"new"}
                    renderSelector={renderSortSelector}
                    onChange={() => {}}
                />
            </div> */}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TopParams.propTypes = {
    className: PropTypes.string.isRequired,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
    sort: PropTypes.any,
    onFilter: PropTypes.func,
}

_TopParams.defaultProps = {}

// ----------------------------------------------------------------------------

const TopParams = styled(_TopParams)`
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: ${({ theme }) => theme.spacing.m}px;
        h1.table-title {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }
        .left,
        .right {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }

        .right {
            flex: 1;
            justify-content: flex-end;
            > div {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }
        }

        .left {
            .page-size-select {
                margin-left: ${({ theme }) => theme.spacing.s}px;
                cursor: pointer;
                .page-size-select-inner {
                    display: flex;
                    align-items: center;

                    .selector-text {
                        font-size: 18px;
                        display: block;
                        .label {
                            color: ${({ theme }) => theme.colors.grey600};
                        }
                        .value {
                            color: black;
                        }
                    }

                    .arrow-down {
                        margin-left: ${({ theme }) => theme.spacing.xs}px;
                    }
                }
            }
        }
        .right {
            .sort-select {
                margin-left: ${({ theme }) => theme.spacing.s}px;
                cursor: pointer;
            }
            .sort-select-inner {
                display: flex;
                align-items: center;

                background-color: white;
                border-radius: 10px;
                overflow: hidden;
                height: ${({ theme }) => theme.spacing.formItemHeight}px;
                .selector-text {
                    height: 100%;
                    padding: 0px 10px;
                    border-right: 1px solid
                        ${({ theme }) => theme.colors.grey300};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .label {
                        color: ${({ theme }) => theme.colors.grey600};
                        margin-right: 4px;
                    }
                    .value {
                    }
                }
                .selector-arrow {
                    width: ${({ theme }) => theme.spacing.formItemHeight}px;
                    height: ${({ theme }) => theme.spacing.formItemHeight}px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .arrow-down {
                        margin-left: ${({ theme }) => theme.spacing.xs}px;
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TopParams
