import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../_shared/context/AppStateStore"

import CommandLine from "./CommandLine"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            const { user, token } = await api.signIn(email, password)
            if (user.role === "admin") {
                dispatch({
                    type: "SIGN_IN_FULFILLED",
                    payload: { user, token },
                })
            } else {
                message.error("L'accesso è constito solo ad amministratori")
            }
        } catch (e) {
            message.error("Credenziali rifiutate")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} w-screen h-screen flex-center`}>
            <form onSubmit={handleSubmit} className="login-card">
                <h3 className="text-center">Login admin</h3>
                <div className="">
                    <AppInput
                        className="auth-input w-full"
                        onChange={setEmail}
                        value={email}
                        placeholder="Email"
                    />
                    <AppInput
                        className="auth-input w-full"
                        onChange={setPassword}
                        value={password}
                        placeholder="Password"
                        type="password"
                    />
                </div>
                <AppButton className="w-full" htmlType="submit">
                    Login
                </AppButton>
            </form>

            <CommandLine />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

_AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const AuthPage = styled(_AuthPage)`
    & {
        .login-card {
            width: 300px;
            padding: 10px;
            border-radius: 6px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

            .auth-input {
                margin-bottom: 20px;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AuthPage
