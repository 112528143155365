import PropTypes from "prop-types"
import React from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import AdminLayout from "../../../_shared/components/AdminLayout"
import Plants from "./Plants"
import Users from "./Users"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getPath(path) {
        return `/admin${path}`
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <AdminLayout className={`${className}`}>
            <Switch>
                <Route exact path={getPath("/")} component={Users} />
                <Route exact path={getPath("/plants")} component={Plants} />
            </Switch>
        </AdminLayout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
